var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AdvancedTimer, CannonJSPlugin, Color4, DirectionalLight, Sound, TransformNode, Vector3, } from '@babylonjs/core';
import { Asteroids } from './astroids';
import { generateRandomInteger } from './game-logic.utils';
import { SDKSettings } from './sdk.settings';
import cannon from 'cannon';
import { createPlayerDummy, createProximityDummy } from './dummies';
import { UI } from './ui';
import { PolyHedra } from './polyhedra';
import { GameState, GameStates } from './game-state';
export const FreeTracking = (rendere, hololinkManager) => __awaiter(void 0, void 0, void 0, function* () {
    /// Load and do everything ready... And as William Wallace said
    /// Waaaait
    /// Use sceneReady to start the game
    const scene = rendere.scene();
    scene.clearColor = new Color4(0, 0, 0, 0.7);
    const settings = yield SDKSettings();
    // Get UI sounds
    // bip sound
    const bip = new Sound('bip', 'sounds/bip.wav', scene, null, {
        spatialSound: true,
        maxDistance: 2000,
    });
    // game over sound
    const gameOver = new Sound('game-over', 'sounds/game-over.wav', scene, null, {
        spatialSound: true,
        maxDistance: 2000,
    });
    gameOver.setVolume(5);
    // start2 sound
    const start2 = new Sound('start2', 'sounds/start2.wav', scene, null, {
        spatialSound: true,
        maxDistance: 2000,
    });
    start2.setVolume(2);
    // complete sound
    const complete = new Sound('complete', 'sounds/complete.wav', scene, null, {
        spatialSound: true,
        maxDistance: 2000,
    });
    // Setup Physics engine
    var gravityVector = new Vector3(0, 0, 0);
    var physicsPlugin = new CannonJSPlugin(true, 10, cannon);
    scene.enablePhysics(gravityVector, physicsPlugin);
    const polyhydra = yield PolyHedra();
    console.log(scene.physicsEnabled ? 'SÅ KØRER FYSIKKEN' : 'FYSIKKEN KØRER SGU IKKE');
    const world = new TransformNode('world', scene);
    const sceneReady = (hlScene) => {
        const camera = scene.activeCamera;
        const gameStates = GameStates();
        const ui = UI(gameStates);
        const asteroids = Asteroids(scene, hlScene.glObjects, playerDummy, settings, ui, polyhydra, gameStates);
        /// Spawn asteroids
        let isPlaying = false;
        const play = () => {
            asteroids.start();
            isPlaying = true;
            const asteroidsSpawnTimer = new AdvancedTimer({
                timeout: 500,
                contextObservable: scene.onBeforeRenderObservable,
            });
            asteroidsSpawnTimer.onTimerEndedObservable.add(() => {
                const currentInterval = Math.max(60000 /
                    (settings.startRate +
                        asteroids.getPopulation() * settings.rateIncrease), 200);
                const fuzzyness = currentInterval * 0.3;
                if (!isPlaying)
                    return;
                const nextAsteroidSpawn = generateRandomInteger(currentInterval - fuzzyness, currentInterval + fuzzyness);
                asteroids.spawn('asteroid-' + asteroids.getPopulation() + 1);
                console.log('spawning delay:', asteroids.getPopulation(), currentInterval);
                asteroidsSpawnTimer.start(nextAsteroidSpawn);
            });
            asteroidsSpawnTimer.start();
        };
        const stop = () => {
            /// Stop spawning astetroids
            isPlaying = false;
            /// Clear existing asteroids
            asteroids.stop();
            asteroids.resetAsteroids();
        };
        /// Set start state
        gameStates.state$.subscribe((s) => {
            console.log('game state updated: ', s);
            ui.display(s);
            switch (s) {
                case GameState.Start:
                    bip.play();
                    let totalCountDown = 3;
                    ui.countDown('Get Ready\n' + totalCountDown.toString());
                    const countDownTimer = new AdvancedTimer({
                        timeout: 1000,
                        contextObservable: scene.onBeforeRenderObservable,
                    });
                    countDownTimer.onTimerEndedObservable.add(() => {
                        totalCountDown--;
                        if (totalCountDown > -1) {
                            if (totalCountDown !== 0) {
                                bip.play();
                            }
                            else {
                                start2.play();
                            }
                            ui.countDown(totalCountDown !== 0
                                ? 'Get Ready\n' + totalCountDown.toString()
                                : 'GO!');
                            countDownTimer.start(1000);
                        }
                        else {
                            countDownTimer.stop();
                            gameStates.next();
                        }
                    });
                    countDownTimer.start();
                    break;
                case GameState.Play:
                    play();
                    break;
                case GameState.Finish:
                    gameOver.play();
                    stop();
                    break;
            }
        });
    };
    // PlayerDummy (a sphere around the camera)
    const playerDummy = createPlayerDummy(scene);
    // PlayerProximityDummy
    const playerProximityDummy = createProximityDummy(scene);
    // Lightning
    var light = new DirectionalLight('light1', new Vector3(4, 1, -0.5), scene);
    light.intensity = 0.7;
    return {
        sceneReady: (scene) => {
            sceneReady(scene);
        },
    };
});
