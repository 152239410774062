import { ActionManager, Angle, PhysicsImpostor, ExecuteCodeAction, Quaternion, Sound, TransformNode, Vector3, StandardMaterial, } from '@babylonjs/core';
import { generateRandomFloat, generateRandomInteger, pickRandomColorHue, } from './game-logic.utils';
import { Explosion } from './particle-systems';
export const Asteroids = (scene, glObjects, playerDummy, settings, ui, polyhedra, gameStates) => {
    const world = scene.getTransformNodeById('world');
    const asteroidsTransformNode = new TransformNode('Astetroids', scene);
    asteroidsTransformNode.parent = world;
    const sceneMeshes = glObjects.filter((o) => o.name.toLocaleLowerCase().includes('logo'));
    sceneMeshes.map((a) => {
        const mesh = scene.getMeshByName(a.name);
        mesh.isPickable = false;
        mesh.visibility = 0.25;
    });
    let isPlaying = true;
    let lives = settings.lives;
    let asteroids = [];
    const asteroidProximityList = new Set();
    const removeAsteroid = (asteroid) => {
        const index = asteroids.findIndex((a) => a.uniqueId == asteroid.uniqueId);
        if (asteroidProximityList.has(asteroid.name)) {
            asteroidProximityList.delete(asteroid.name);
        }
        asteroid.dispose();
    };
    const getPopulation = () => {
        return asteroids.length;
    };
    const resetAsteroids = () => {
        asteroids = [];
        asteroidProximityList.clear();
    };
    // ************************************************************************************************
    // spawn sound
    const spawnSound = new Sound('bup', 'sounds/bup.wav', scene, null, {
        spatialSound: true,
        maxDistance: 2000,
    });
    // Blast sound
    const asteroidBlast = new Sound('blast', 'sounds/explosion-far.wav', scene, null, { spatialSound: true, maxDistance: 2000 });
    // crash sound
    const asteroidCrash = new Sound('crash', 'sounds/crash.wav', scene, null, {
        spatialSound: true,
        maxDistance: 2000,
    });
    // proximity alert sound
    const asteroidProximityAlert = new Sound('proximity-alert', 'sounds/prox-alert.mp3', scene, null, { spatialSound: true, maxDistance: 2000 });
    asteroidProximityAlert.setVolume(0.5);
    // Create a asteroidExplosion system
    const asteroidExplosion = Explosion('asteroidExplosion', 'sphere', scene);
    const asteroidRingExplosion = Explosion('ringExplosion', 'cylinder', scene);
    // ************************************************************************************************
    //Handling of asteroid being hit
    const asteroidHit = (asteroid) => {
        var origin = asteroid.getAbsolutePosition();
        origin.x += Math.random() * 2 - 1;
        origin.y += Math.random() * 2 - 1;
        origin.z += Math.random() * 2 - 3;
        // set the explosion position to the position of the asteroid
        asteroidExplosion.emitter = origin;
        asteroidRingExplosion.emitter = origin;
        // set the explosion sound to the position of the asteroid
        asteroidBlast.setPosition(origin);
        asteroidBlast.play();
        // remove the asteroid
        // asteroid.dispose();
        removeAsteroid(asteroid);
        // start the explosion particle system
        asteroidExplosion.start();
        if (Math.random() > 0.4) {
            asteroidRingExplosion.start();
        }
    };
    // Handling of playerDummy being hit by asteroid
    const playerDummyHit = (asteroid) => {
        const mesh = asteroid.object;
        var origin = mesh.getAbsolutePosition();
        // set the explosion position to the position of the asteroid
        asteroidExplosion.emitter = mesh.position;
        // remove the asteroid
        removeAsteroid(mesh);
        asteroidCrash.setPosition(origin);
        asteroidCrash.play();
        console.log('PLAYER HIT!!!! ');
        lives--;
        ui.updateLives(lives);
        if (lives === 0) {
            gameStates.next();
        }
        // start the explosion particle system
        asteroidExplosion.start();
    };
    // ************************************************************************************************
    /// Create rotation node from world
    const spawnRotation = new TransformNode(`spawn-rotation`, scene);
    spawnRotation.parent = world;
    const spawnPoint = new TransformNode(`spawn-position`, scene);
    spawnPoint.parent = spawnRotation;
    const totalPolyhydras = polyhedra.polyhedra.length;
    const spawn = (name) => {
        // Create an asteroid
        const asteroid = polyhedra.create(scene, `${name}-${asteroids.length}`, generateRandomInteger(0, totalPolyhydras - 1));
        const material = new StandardMaterial(`${name}-${asteroids.length}-mat`);
        material.diffuseColor = pickRandomColorHue();
        asteroid.material = material;
        asteroid.actionManager = new ActionManager(scene);
        asteroid.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, (evt) => {
            asteroidHit(asteroid);
            ui.updateScore(200);
        }));
        const maxAngleAlpha = Angle.FromDegrees(settings.maxAngleAlpha).radians();
        const maxAngleBeta = Angle.FromDegrees(settings.maxAngleBeta).radians();
        spawnRotation.rotationQuaternion = Quaternion.FromEulerAngles(generateRandomFloat(-maxAngleAlpha * 0.5, maxAngleAlpha * 0.5, 2), generateRandomFloat(-maxAngleBeta * 0.5, maxAngleBeta * 0.5, 2), 0);
        spawnPoint.position = new Vector3(0, 0, generateRandomInteger(1500, 2000));
        /// Set spawn position
        asteroid.setAbsolutePosition(spawnPoint.getAbsolutePosition());
        spawnSound.setPosition(asteroid.position);
        spawnSound.play();
        // Add a physicsImpostor
        // console.log(realmesh);
        asteroid.physicsImpostor = new PhysicsImpostor(asteroid, PhysicsImpostor.SphereImpostor, {
            mass: 10,
            friction: 1.0,
            restitution: 0.9,
            ignoreParent: true,
        }, scene);
        //set a random angular velocity (rotation)
        // console.log('angularVel: ', asteroidClone);
        asteroid.physicsImpostor.setAngularVelocity(new Vector3(Math.pow(Math.random() * 3 - 2, 2), Math.pow(Math.random() * 3 - 2, 2), Math.pow(Math.random() * 3 - 2, 2)));
        // calculate the vector from the box position to the camera position
        const playerVector = asteroid.position
            .subtract(new Vector3(0, 0, 0))
            .normalize()
            .scale(-generateRandomInteger(100, 150));
        // set a linear velocity along the playervector
        asteroid.physicsImpostor.setLinearVelocity(playerVector);
        asteroid.physicsImpostor.registerOnPhysicsCollide(playerDummy.physicsImpostor, playerDummyHit);
        asteroid.physicsImpostor.registerAfterPhysicsStep((imp) => {
            const mesh = imp.object;
            if (mesh.intersectsMesh(scene.getMeshByName('proximityDummy')) &&
                !asteroidProximityList.has(mesh.name)) {
                asteroidProximityList.add(mesh.name);
            }
            if (!mesh.intersectsMesh(scene.getMeshByName('proximityDummy')) &&
                asteroidProximityList.has(mesh.name)) {
                asteroidProximityList.delete(mesh.name);
            }
            //play and loop proximity alert sound if asteroidProximityList size is larger than 0
            if (asteroidProximityList.size > 0 &&
                !asteroidProximityAlert.isPlaying) {
                asteroidProximityAlert.loop = true;
                asteroidProximityAlert.setPosition(mesh.position);
                asteroidProximityAlert.play();
            }
            else if (asteroidProximityList.size === 0 &&
                asteroidProximityAlert.isPlaying) {
                asteroidProximityAlert.stop();
            }
        });
        asteroids.push(asteroid);
    };
    return {
        start: () => {
            lives = settings.lives;
            ui.updateLives(lives);
            ui.resetScore();
        },
        getPopulation,
        proximityAlerts: asteroidProximityList,
        spawn,
        resetAsteroids,
        stop: () => {
            isPlaying = false;
            asteroids.forEach((a) => {
                a.dispose();
            });
            asteroidProximityAlert.stop();
            asteroidProximityList.clear();
        },
    };
};
