export var ViewerState;
(function (ViewerState) {
    ViewerState[ViewerState["Normal"] = 0] = "Normal";
    ViewerState[ViewerState["CustomBranding"] = 1] = "CustomBranding";
    ViewerState[ViewerState["Embedded"] = 2] = "Embedded";
})(ViewerState || (ViewerState = {}));
export var CustomBrandingState;
(function (CustomBrandingState) {
    CustomBrandingState[CustomBrandingState["Loader"] = 0] = "Loader";
    CustomBrandingState[CustomBrandingState["Splash"] = 1] = "Splash";
    CustomBrandingState[CustomBrandingState["Scanner"] = 2] = "Scanner";
})(CustomBrandingState || (CustomBrandingState = {}));
export const ViewerStates = (() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('custombranding')) {
        const value = params.get('custombranding');
        if (value === 'loader') {
            return {
                state: ViewerState.CustomBranding,
                substate: CustomBrandingState.Loader,
            };
        }
        if (value === 'splash') {
            return {
                state: ViewerState.CustomBranding,
                substate: CustomBrandingState.Splash,
            };
        }
        if (value === 'scanner') {
            return {
                state: ViewerState.CustomBranding,
                substate: CustomBrandingState.Scanner,
            };
        }
    }
    return {
        state: ViewerState.Normal,
    };
})();
