// import { GUIElement } from '../../../../../library/src/models/hololink/gui.model';
/**
 * Assetloader wraps Babylons AssetManager, for handling multiple tasks.
 * And is extended to handle task outside AssetManager like surfaces used for video
 * @param assetManager
 */
export const AssetLoader = (assetManager) => {
    let taskCount = 0;
    let assetTasks = [];
    const handleAssetTasks = (assetTasks, type) => {
        const run = (task) => task.onSuccess();
        assetTasks.filter((a) => a.type === type).map(run);
    };
    return {
        loadGlObject: (model) => {
            taskCount++;
            switch (model.type) {
                case 'model':
                    return assetManager.addMeshTask(`${taskCount} - Model Task`, '', model.glbUrl, '');
                case 'surface':
                    // Create empty asset task object, and add it to the task list
                    const surfaceTask = {
                        type: 'Surface',
                        onSuccess: () => { },
                        onError: () => { },
                    };
                    assetTasks = [...assetTasks, surfaceTask];
                    return surfaceTask;
                case 'geometry':
                    const geometryTask = {
                        type: 'geometry',
                        onSuccess: () => { },
                        onError: () => { },
                    };
                    assetTasks = [...assetTasks, geometryTask];
                    return geometryTask;
                default:
                    assertExhaustive();
            }
        },
        loadBinary: (binaryUrl) => {
            taskCount++;
            return assetManager.addBinaryFileTask(`${taskCount} - Audio Task`, binaryUrl);
        },
        // progess$: progress$.asObservable(),
        taskCount: () => taskCount,
        loadAssets: () => {
            return new Promise((resolve, reject) => {
                assetManager.loadAsync();
                /// TODO update as array
                handleAssetTasks(assetTasks, 'Surface');
                handleAssetTasks(assetTasks, 'geometry');
                //  Solve asset task outside assetmanager
                if (taskCount === assetTasks.length) {
                    handleAssetTasks(assetTasks, 'Gui');
                    resolve();
                }
                // This one might be depricated due to the error handling in the individual tasks
                assetManager.onTaskError = (task) => { };
                // Callback when all tasks are solved
                assetManager.onFinish = (tasks) => {
                    assetManager.reset();
                    handleAssetTasks(assetTasks, 'Gui');
                    resolve();
                };
            });
        },
    };
};
function assertExhaustive() {
    throw new Error('Function not implemented.');
}
