export const showElements = (selectors) => {
    selectors.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
            element.classList.remove('hidden');
        });
    });
};
export const hideElements = (selectors) => {
    selectors.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
            element.classList.add('hidden');
        });
    });
};
export const removeAllChildren = (elm) => {
    [...elm.childNodes].map(removeAllChildren);
    elm.parentElement.removeChild(elm);
};
