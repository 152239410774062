import { hideElements, showElements } from '../viewer.utils';
export const openModal = (function () {
    return {
        show: (message) => {
            const modalText = (document.querySelector('#modal-text'));
            modalText.innerHTML = message;
            showElements(['#modal']);
        },
        hide: () => {
            hideElements(['#modal']);
        },
    };
})();
export const screenshotModal = (function () {
    return {
        show: (screenshotUrl) => {
            const screenshot = (document.querySelector('#screenshot'));
            screenshot.src = screenshotUrl;
            showElements(['#screenshot-modal', '#screenshot-text-container']);
        },
        hide: () => {
            hideElements(['#screenshot-modal', '#screenshot-text-container']);
        },
    };
})();
