var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HololinkError } from './base.exception';
import { openModal } from '../services/modal.service';
export const ErrorHandling = () => __awaiter(void 0, void 0, void 0, function* () {
    window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
        return false;
    };
    const standardErrorMessage = 'An unexpected error occured';
    const handleError = (err) => {
        if (err instanceof HololinkError) {
            console.error(err.logMessage);
            showError(err.userMessage);
        }
        // ? showError(err.userMessage)
        // : showError(standardErrorMessage);
    };
    // Promise unhandled exceloptions ends up here
    window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
        promiseRejectionEvent.promise.catch(handleError);
        promiseRejectionEvent.preventDefault();
    });
    // Promise handled exceptions ends up here
    window.addEventListener('rejectionhandled', (event) => {
        console.log('Promise rejected; reason: ' + event.reason);
    }, false);
    // Set text in modal and show modal
    const showError = (message) => {
        console.log('message: ', message);
        openModal.show(message);
    };
    const logError = (logEntry) => {
        // TODO
    };
});
