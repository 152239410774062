var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Exception } from 'handlebars';
export const getImageFromUrl = (src) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(src);
    const blob = yield response.blob();
    const image = new Image();
    image.src = URL.createObjectURL(blob);
    yield new Promise((resolve) => image.addEventListener('load', resolve));
    return image;
});
export const getImageDataFromImageElement = (image, squared = false) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d', {
        alpha: true,
    });
    /// Square tracking image
    if (squared) {
        throw new Exception('Please do now use this function for images!');
        if (image.height > image.width) {
            console.log('image height');
            canvas.width = image.height;
            canvas.height = image.height;
        }
        else if (image.height < image.width) {
            canvas.width = image.width;
            canvas.height = image.width;
        }
        else {
            // Already squared
            canvas.width = image.width;
            canvas.height = image.height;
        }
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
        // Draw image in center
        context.drawImage(image, canvas.width / 2 - image.width / 2, canvas.height / 2 - image.height / 2);
    }
    else {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
    }
    // const testImage = canvas.toDataURL('image/png');
    // console.log('testImage: ', testImage);
    return context.getImageData(0, 0, canvas.width, canvas.height);
};
export const getImageDataFromUrl = (src, squareImage = false) => __awaiter(void 0, void 0, void 0, function* () {
    const image = yield getImageFromUrl(src);
    console.log('image: ', image.height);
    return getImageDataFromImageElement(image, squareImage);
});
export const base64toBlob = (dataUrl) => {
    const byteString = atob(dataUrl.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
};
