var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { CanvasElement } from '../element-factories/canvas.factory';
import { getImageDataFromUrl } from '../utilities/dom/image.utils';
import { hideElements, showElements } from '../viewer.utils';
export const TrackingManager = (workerHandler, videoHandler) => __awaiter(void 0, void 0, void 0, function* () {
    /// Setup webworker in a service worker
    console.log('workerHandler: ', workerHandler);
    const videoCanvas = document.getElementById('videoCanvas');
    /// Clousre states
    const trackingState$ = new BehaviorSubject({
        isTracked: false,
        modelMatrix: null,
    });
    const trackingStateChanged$ = new BehaviorSubject(false);
    const screenSize = videoHandler.size();
    let srcCanvas = CanvasElement(screenSize);
    /// Encapsulating tracking loop
    let updateTrackingState = true;
    // const frameRate = videoHandler.settings().frameRate;
    // console.log('frameRate: ', frameRate);
    let currentFrame;
    const startTracking = () => __awaiter(void 0, void 0, void 0, function* () {
        yield workerHandler.init(screenSize.height, screenSize.width);
        let imgData;
        let previous, deltaT;
        /// We set the process frame rate to 30 fps
        const frameTime = 30;
        let deltaFrameTime = 0;
        /// Show video canvas and hide video elm
        showElements(['#videoCanvas']);
        hideElements(['#inputVideo']);
        /// NEVER BLOK THIS LOOP!
        const trackingLoop = (totalMs) => {
            /// Sync frame with frame time
            if (totalMs > 0) {
                deltaT = totalMs - previous;
                deltaFrameTime += deltaT;
            }
            if (deltaFrameTime > frameTime) {
                deltaFrameTime = 0;
                srcCanvas.draw(videoHandler.element);
                imgData = srcCanvas.getImageData();
                workerHandler.processFrame(imgData, totalMs).then((result) => {
                    trackingState$.next(result);
                    if (trackingStateChanged$.value !== result.isTracked)
                        trackingStateChanged$.next(result.isTracked);
                });
            }
            previous = totalMs;
            currentFrame = requestAnimationFrame(trackingLoop);
        };
        console.log('Start tracking');
        trackingLoop(0);
    });
    const stopTracking = () => {
        cancelAnimationFrame(currentFrame);
    };
    const setVideoCanvas = (scale) => {
        videoCanvas.width = screenSize.width;
        videoCanvas.height = screenSize.height;
        const videoCanvasCtx = videoCanvas.getContext('2d', {
            alpha: false,
        });
        videoCanvasCtx.scale(scale, scale);
    };
    setVideoCanvas(1);
    let target;
    return {
        setTarget: (templateUrl) => __awaiter(void 0, void 0, void 0, function* () {
            templateUrl = templateUrl;
            target = yield getImageDataFromUrl(templateUrl);
            return workerHandler.setMarker(target);
        }),
        getTarget: () => target,
        /**
         * Starts the tracking loop. Shows webcam frame but still not pushing tracking event.
         * Call enableTracking for tracking events.
         */
        start: () => __awaiter(void 0, void 0, void 0, function* () {
            startTracking();
        }),
        /**
         * Enables tracking
         */
        enableTracking: () => {
            updateTrackingState = true;
        },
        disableTracking: () => {
            updateTrackingState = false;
        },
        stop: () => {
            stopTracking();
            workerHandler.destroy();
        },
        size: videoHandler.size(),
        trackingStateChanged$: trackingStateChanged$.asObservable(),
        trackingState$: trackingState$.asObservable(),
    };
});
