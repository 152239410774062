import { HololinkError } from "./base.exception";
export class SchemaVersionError extends HololinkError {
    constructor(currentSchemaVersion, schemaVersion, ...args) {
        super(...args);
        this.userMessage = `Please update this document to version ${currentSchemaVersion} using the Hololink editor`;
        this.logMessage = `Wrong Hololink version ${schemaVersion}, we only understand ${currentSchemaVersion}`;
        // console.log('this.logMessage: ', this.logMessage);
        Error.captureStackTrace(this, SchemaVersionError);
    }
}
