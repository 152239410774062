import { BehaviorSubject } from 'rxjs';
export var GameState;
(function (GameState) {
    GameState[GameState["Start"] = 0] = "Start";
    GameState[GameState["Play"] = 1] = "Play";
    GameState[GameState["Finish"] = 2] = "Finish";
})(GameState || (GameState = {}));
export const GameStates = () => {
    const state$ = new BehaviorSubject(GameState.Start);
    return {
        state$: state$.asObservable(),
        next: () => {
            const length = Object.keys(GameState).length / 2;
            console.log('length: ', length);
            const index = Object.keys(GameState).findIndex((v) => v === state$.getValue().toString());
            const nextIndex = index < length - 1 ? index + 1 : 0;
            console.log('nextIndex: ', nextIndex);
            state$.next(nextIndex);
        },
    };
};
