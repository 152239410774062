var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { currentSchemaVersion, } from '../../../library/src/models/hololink/hololink.model';
import { HololinkRequestErrors } from '../../../library/dist/utilities/response-errors';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { SchemaVersionError } from '../error-handling/schema.exception';
import { HololinkError } from '../error-handling/base.exception';
import { openModal } from '../services/modal.service';
import { hideElements } from '../viewer.utils';
import { holoDocLoaded } from '../eventhandlers/hololink-document-loaded.handler';
// import { holoDocLoaded } from '..';
const Settings = {
    domain: process.env.API_URL,
};
const getHololinkByToken = (token) => __awaiter(void 0, void 0, void 0, function* () {
    if (!token) {
        throw new HololinkError({
            userMessage: 'Not a valid hololink',
            logMessage: 'No id supplied',
        });
    }
    const url = `${Settings.domain}/hololink/preview/${token}`;
    try {
        const response = yield axios.get(url);
        window['hl'] = response.data;
        document.dispatchEvent(holoDocLoaded);
        return response.data;
    }
    catch (error) {
        const errorResponse = error.response;
        if (errorResponse &&
            errorResponse.status === 409 &&
            errorResponse.data.error ===
                HololinkRequestErrors['Token not valid']) {
            openModal.show(`This preview link has expired </br>
                Get a new preview link from the Hololink editor`);
            return;
        }
        throw new HololinkError({
            userMessage: 'Not a valid hololink',
            logMessage: 'Not a valid id',
        });
    }
});
const getToken = () => {
    const url = new URL(window.location.href);
    const token = url.pathname.replace('/', '');
    return token;
};
const getHololink = () => __awaiter(void 0, void 0, void 0, function* () {
    if (process.env.STATE === 'published') {
        return getPublishedHololink();
    }
    if (process.env.STATE === 'preview') {
        return getPreviewHololink();
    }
    if (process.env.STATE === 'debug') {
        return getHololinkById();
    }
});
const getPreviewHololink = () => __awaiter(void 0, void 0, void 0, function* () {
    return getHololinkByToken(getToken());
});
const getPublishedHololink = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = process.env.HOLOLINK_URL
            ? yield axios.get(process.env.HOLOLINK_URL)
            : yield axios.post(`${Settings.domain}/hololink/published-safe`, {
                url: window.location.href,
            });
        if (response.data.publishing.status === 'published' &&
            response.data.publishing.options.removeWatermark === true) {
            hideElements(['#watermark']);
        }
        window['hl'] = response.data;
        document.dispatchEvent(holoDocLoaded);
        return response.data;
    }
    catch (error) {
        const errorResponse = error.response;
        if (errorResponse &&
            errorResponse.status === 409 &&
            errorResponse.data.error ===
                HololinkRequestErrors['Daily Views exceeded']) {
            openModal.show(`This is a test Hololink, with no more daily views. </br>
            Publishing it gives unlimited views.`);
        }
    }
});
/**
 * This should only be used for debugging
 */
const getHololinkById = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const urlRef = new URL(window.location.href);
    const id = urlRef.pathname.replace('/', '');
    console.log('process.env.HOLOLINK_URL: ', process.env.HOLOLINK_URL);
    const url = (_a = process.env.HOLOLINK_URL) !== null && _a !== void 0 ? _a : `${Settings.domain}/hololink/published/${id}`;
    try {
        const response = yield axios.get(url);
        window['hl'] = response.data;
        document.dispatchEvent(holoDocLoaded);
        return response.data;
    }
    catch (error) {
        const errorResponse = error.response;
        throw new HololinkError({
            userMessage: errorResponse.data,
            logMessage: 'Not a valid id',
        });
    }
});
export const HololinkManager = () => __awaiter(void 0, void 0, void 0, function* () {
    const hololink = yield getHololink();
    // typescript might complain here, since in our type definition, the version
    // is always the same as the understood version.
    // HOWEVER, typescript does not add runtime checks to ensure that this is always the
    // case, and it is perfectly possible to receive JSON and not have it correspond to
    // the type that we claim it is. In other words, Typescript is full of lies.
    if (hololink.schemaVersion !== currentSchemaVersion) {
        return Promise.reject(new SchemaVersionError(currentSchemaVersion, hololink.schemaVersion));
    }
    // Set subject and initial scene as start scene
    let currentSceneId = hololink.startScene;
    const scene$ = new BehaviorSubject(hololink.scenes[currentSceneId]);
    const _beforeSceneChange$ = new BehaviorSubject(null);
    return {
        hololink: () => hololink,
        setScene: (id) => {
            _beforeSceneChange$.next(currentSceneId);
            currentSceneId = id;
            const newScene = hololink.scenes[currentSceneId];
            newScene && scene$.next(newScene);
        },
        currentScene: () => scene$.getValue(),
        currentSceneId: () => currentSceneId,
        beforeSceneChange$: _beforeSceneChange$.asObservable(),
        currentScene$: scene$.asObservable(),
    };
});
