import { Color3 } from '@babylonjs/core';
export const generateRandomInteger = (min, max) => {
    return Math.floor(min + Math.random() * (max - min + 1));
};
export const generateRandomFloat = (min, max, decimals) => {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    return parseFloat(str);
};
// Picks a random color from an array of hex values
export const pickRandomColorFromList = () => {
    const colors = ['#667095', '#FFBF00', '#E50D7B', '#E0E2EA'];
    const color = colors[Math.floor(Math.random() * colors.length)];
    return color;
};
export const pickRandomColorHue = () => {
    const hue = generateRandomInteger(0, 360);
    const color = Color3['FromHSV'](hue, 0.5, 1);
    return color;
};
