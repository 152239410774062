var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GetConstraint, VideoElement, } from '../element-factories/video.factory';
import { isMobile } from '../managers/webcam.manager';
import { ScreenSize } from '../utilities/dom/screen-size';
export const VideoManager = () => __awaiter(void 0, void 0, void 0, function* () {
    const videoConstraint = isMobile()
        ? {
            width: ScreenSize.height,
            height: ScreenSize.width,
            facingMode: 'environment',
            frameRate: 30,
        }
        : GetConstraint('vga');
    console.log('videoConstraint: ', videoConstraint);
    const video = yield VideoElement(videoConstraint);
    /// Video canvas where we draw the video stream
    const videoCanvas = document.getElementById('videoCanvas');
    // Start the video
    const startVideo = () => __awaiter(void 0, void 0, void 0, function* () {
        //alert('Så starter kameraet!');
        yield video.load();
        yield video.play();
        const size = video.size();
        const settings = video.getSettings();
        console.log('settings: ', settings);
        // Center the video acording to the size of the video stream
        let root = document.documentElement;
        //alert('tracking' + size.width + 'x' + size.height);
        root.style.setProperty('--video-width', `${size.width}px`);
        root.style.setProperty('--video-height', `${size.height}px`);
        return {
            width: size.width,
            height: size.height,
        };
    });
    const setVideoCanvas = (scale) => {
        videoCanvas.width = video.size().width;
        videoCanvas.height = video.size().height;
        const videoCanvasCtx = videoCanvas.getContext('2d', {
            alpha: false,
        });
        if (scale !== 1) {
            videoCanvasCtx.scale(scale, scale);
        }
    };
    /// Create canvas with a uniformscale 1 do not scale
    setVideoCanvas(1);
    const videoCanvasCtx = videoCanvas.getContext('2d', {
        alpha: false,
    });
    const element = video.element();
    return {
        startVideo,
        element,
        showVideoElement: (show) => video.visible(show),
        // videoElement: () => video.element(),
        size: () => video.size(),
        settings: () => video.getSettings(),
        videoCanvasCtx,
    };
});
