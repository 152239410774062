var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DetectorWorker from 'worker-loader!./detector.worker';
export const DetectorHandler = () => {
    const detectorWorker = new DetectorWorker();
    let listeners = new Map();
    /// Handle all messages return from  our wasm worker
    detectorWorker.onmessage = (event) => {
        if (!listeners.has(event.data.name))
            return;
        listeners.get(event.data.name)(event.data.result);
    };
    let detectorFunctions;
    // detectorFunctions.functions.
    return {
        load: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'load',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('load', () => resolve());
            });
        },
        init: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'init',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('init', () => resolve());
            });
        },
        reset: (...args) => {
            return new Promise((resolve, reject) => {
                detectorWorker.postMessage({
                    name: 'reset',
                    args,
                });
                listeners.set('reset', () => resolve());
            });
        },
        setMarker: (imageData) => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'setModel',
                    args: [imageData],
                });
                /// Add resolve to listeners as a clousre
                listeners.set('setModel', (rect) => resolve(rect));
            });
        },
        getModelRect: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'getModelRect',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('getModelRect', (rect) => resolve(rect));
            });
        },
        setFrameSrc: (width, height) => __awaiter(void 0, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                detectorWorker.postMessage({
                    name: 'setFrameSrc',
                    args: [width, height],
                });
                listeners.set('setFrameSrc', () => resolve());
            });
        }),
        processFrame: (imageData, timestamp) => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'processFrame',
                    args: [imageData, timestamp],
                });
                /// Add resolve to listeners as a clousre
                listeners.set('processFrame', () => resolve());
            });
        },
        generateResult: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'generateResult',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('generateResult', (result) => resolve(result));
            });
        },
        destroy: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                detectorWorker.postMessage({
                    name: 'destroy',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('destroy', () => resolve());
            });
        },
    };
};
