var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ErrorHandling } from './error-handling/error-handling';
import { screenshotModal } from './services/modal.service';
import { HandleOrientation } from './eventhandlers/orientation.handle';
import { startViewer } from './viewer.component';
import { HololinkManager } from './managers/hololink.manager';
import { Splash } from './splash';
import { BabylonRenderer } from './webgl/babylon.renderer';
import { ModelManager } from './managers/scene.manager';
import { changeLoadingText } from './loading/loading-utils';
import { hideElements, removeAllChildren, showElements } from './viewer.utils';
import { InitializeStatistics } from './statistics/statistics';
import { Scanner } from './scanner';
import { WorkerHandler } from './workers/worker.handler';
import { CustomBrandingState, ViewerState, ViewerStates, } from './viewer.states';
import { HasSceneType } from '../../library/dist/utilities/hololink.utils';
import { InitialSceneType } from './scene-types/initial.tracking-type';
import { Sensors } from './sensors/sensors';
import { FreeTracking } from './sdk/free-tracking';
import { ScreenSize } from './utilities/dom/screen-size';
// import { InitialSceneType } from './scene-types/initial.tracking-type';
/// Events
document.addEventListener('DOMContentLoaded', theDomHasLoaded, false);
window.addEventListener('orientationchange', HandleOrientation, false);
/// TODO move this to another place
document
    .getElementById('screenshot-close-icon')
    .addEventListener('click', screenshotModal.hide);
/// DOM is loaded start the splash
function theDomHasLoaded(e) {
    return __awaiter(this, void 0, void 0, function* () {
        ViewerStates;
        changeLoadingText(0);
        const isPortrait = HandleOrientation();
        let vh;
        // Determine the viewport height in pixels
        if (isPortrait) {
            vh = window.outerHeight;
        }
        else {
            vh = window.outerWidth;
        }
        // We set the screen CSS height variable
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        const hud = document.getElementById('hud');
        // We also set the base font size for the HUD/interface.
        // This font size should match the font size used for the
        // interface in the editor in a relative manner
        // (i.e. viewport height divided by the same factor as in the editor)
        hud.style.setProperty('font-size', `${vh / 36}px`);
        const startContainer = document.getElementById('full-screen-container');
        const loadingScreen = document.getElementById('loading');
        ErrorHandling().then();
        /// Load the hololink document
        const hololinkManager = yield HololinkManager();
        const hololink = hololinkManager.hololink();
        /// Get tracking types in hololink
        // const trackingTypes = GetSceneTypesInHololinks(hololink);
        const initialSceneType = InitialSceneType(hololink);
        console.log('initialSceneType: ', initialSceneType);
        let workerHandler;
        if (initialSceneType.type === 'marker') {
            /// Load workers
            /// Do not load works if state custom branding
            if (ViewerStates.state !== ViewerState.CustomBranding) {
                workerHandler = yield WorkerHandler();
            }
            /// Set anchor the the initial scene
            // const anchorUrl = getAnchorUrl(initialScene, hololink);
            Scanner(initialSceneType.anchorUrl);
        }
        /// Initialize statistics
        const statisticsController = InitializeStatistics(hololink);
        changeLoadingText(1);
        /// Initialize rendere and start loading content
        const canvas = document.querySelector('#renderCanvas');
        canvas.width = ScreenSize.width;
        canvas.height = ScreenSize.height;
        const renderer = yield BabylonRenderer(canvas, hololink);
        /// Start splash
        const splash = Splash(hololinkManager);
        changeLoadingText(2);
        /// This loads the first scene
        const modelManager = yield ModelManager(hololink, renderer);
        /// Load SDKish
        const sdk = yield FreeTracking(renderer, hololinkManager);
        /// Keep showing loader if custom branding loader
        if (ViewerStates.state === ViewerState.CustomBranding &&
            ViewerStates.substate === CustomBrandingState.Loader) {
            return;
        }
        splash.ready();
        document.onclick = () => __awaiter(this, void 0, void 0, function* () {
            const audio = new Audio('sounds/completed.wav');
            audio.volume = 0.5;
            audio.play();
            /// If Custom branding and splash disable
            if (ViewerStates.state === ViewerState.CustomBranding &&
                ViewerStates.substate === CustomBrandingState.Splash) {
                return;
            }
            /// If any scene is 360 we need to ask permission for sensors
            if (HasSceneType(hololink, '360')) {
                yield Sensors();
            }
            /// Load cameras
            renderer.loadCameras();
            renderer.start();
            /// video hack for ios.
            /// We need to start video on button event. then pause and play when automatic action triggers scene change.
            /// See issue https://gitlab.com/holofox/hololink/-/issues/851
            renderer.startVideos();
            removeAllChildren(startContainer);
            removeAllChildren(loadingScreen);
            // modelManager.startScene(hololinkManager.currentSceneId());
            showElements([
                '#hud',
                '#inputVideo',
                '#renderCanvas',
                '#scanner',
                '#scanner-frame-settings',
                '#image-target',
                '.always',
            ]);
            hideElements(['#watermark']);
            startViewer(hololinkManager, statisticsController, initialSceneType, renderer, modelManager, workerHandler, sdk);
            document.onclick = () => false;
        });
    });
}
// function pageFullyLoaded() {}
