import { Ellipse, Line, Rectangle, TextBlock, } from '@babylonjs/gui';
import { getAdvancedDynamicTexture } from './gui.functions';
export const AnnotateObject = (scene, glObject, mesh) => {
    if (glObject.annotaion) {
        const adt = getAdvancedDynamicTexture(scene);
        annotation2Control(glObject, mesh, adt);
    }
};
const annotation2Control = (glObject, mesh, adt) => {
    var _a, _b, _c, _d;
    const annotation = glObject.annotaion;
    let rect = new Rectangle(`textbox-${glObject.id}`);
    rect.height = `${annotation.height}px`;
    rect.width = `${annotation.width}px`;
    rect.color = annotation.foregroundColor;
    rect.background = annotation.backgroundColor;
    rect.cornerRadius = (_a = annotation === null || annotation === void 0 ? void 0 : annotation.cornerRadius) !== null && _a !== void 0 ? _a : 10;
    rect.thickness = (_b = annotation === null || annotation === void 0 ? void 0 : annotation.borderThickness) !== null && _b !== void 0 ? _b : 3;
    adt.addControl(rect);
    rect.linkWithMesh(mesh);
    rect.linkOffsetY = -150;
    rect.metadata = {
        meshId: mesh.id,
        sceneId: mesh.metadata.sceneId,
    };
    rect.isVisible = false;
    let label = new TextBlock(`text-${glObject.id}`);
    label.text = annotation.text;
    label.textWrapping = true;
    label.fontSize = (_c = annotation === null || annotation === void 0 ? void 0 : annotation.fontSize) !== null && _c !== void 0 ? _c : 18;
    label.fontFamily = (_d = annotation === null || annotation === void 0 ? void 0 : annotation.fontFamily) !== null && _d !== void 0 ? _d : 'Arial';
    rect.addControl(label);
    let target = new Ellipse(`target-${glObject.id}`);
    target.width = '20px';
    target.height = '20px';
    target.color = annotation.foregroundColor;
    target.thickness = 3;
    target.background = annotation.backgroundColor;
    adt.addControl(target);
    target.linkWithMesh(mesh);
    target.metadata = {
        meshId: mesh.id,
        sceneId: mesh.metadata.sceneId,
    };
    target.isVisible = false;
    let line = new Line(`line-${glObject.id}`);
    line.lineWidth = 3;
    line.color = annotation.foregroundColor;
    line.y2 = `${annotation.height / 2}px`;
    line.linkOffsetY = -8;
    adt.addControl(line);
    line.linkWithMesh(mesh);
    line.connectedControl = rect;
    line.metadata = {
        meshId: mesh.id,
        sceneId: mesh.metadata.sceneId,
    };
    line.isVisible = false;
};
