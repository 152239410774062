import { Mesh } from '@babylonjs/core';
/**
 * Takes a mesh and an arrays of transforms. Transforms are applied recurive starting from index 0.
 * @param mesh
 * @param transforms
 */
/// Not pure, due to the nature of Babylons meshes.
export const transformMesh = (mesh, transforms) => transforms.length > 0
    ? transformMesh(transforms[0](mesh), transforms.filter((t, i) => i !== 0))
    : mesh;
/**
 * Create a mesh from a GlModel
 * @param scene
 */
export const createEmptyMeshFromGlModel = (scene) => (glModel) => {
    const mesh = new Mesh(glModel.name, scene);
    mesh.id = glModel.id;
    return mesh;
};
