"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultSplash = void 0;
exports.defaultSplash = {
    loader: {
        bgColor: "#8c66c2",
        textColor: "#ffffff",
        bgImage: "",
        modalBgColor: "#E0AF0E",
        modalTextColor: "#ffffff",
        spinnerImage: "https://assets.hololink.io/viewer/images/loading-icon.png",
        spinnerImageSize: 50,
        loadingText: "Doing the heavy lifting",
        progressText: [
            "Initializing",
            "Prototyping the prototype!",
            "Interviewing 3D models …",
            "Augmenting your design, We are almost there!",
        ],
    },
    splash: {
        tapText: "Tap screen to launch your AR experience",
        bgColor: "#8c66c2",
        textColor: "#ffffff",
        bgImage: "",
        bgOpacity: 0.5,
    },
    title: "Hololink | View",
    showAnchorLocator: true,
    tcText: "By using this experience you accept our",
    tcLinkText: "terms & conditions",
    tcLink: "https://www.hololink.io/terms",
    favicon: "https://assets.hololink.io/viewer/images/viewer-favicon.png",
};
