"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.switchDefaultAssert = exports.assertExhaustive = void 0;
/**
 * Always add this to the default case
 * and you will get an exception when a case is missing
 */
function assertExhaustive(value, message = "Reached unexpected case in exhaustive switch") {
    throw new Error(message);
}
exports.assertExhaustive = assertExhaustive;
const switchDefaultAssert = (never) => {
    // throw new Error('')
};
exports.switchDefaultAssert = switchDefaultAssert;
