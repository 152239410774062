import { fromPromise } from 'rxjs/internal/observable/fromPromise';
// TODO move to library and use throughout!
/// Get array of Hololink scenes, which can be navigated to from sceneId
function getExits(sceneId, hololink) {
    var _a, _b, _c, _d;
    const hlScene = hololink.scenes[sceneId];
    const sceneGlObjects = hlScene.glObjects;
    const targets = new Set();
    // Find all hud elements that point to other scenes
    hololink.scenes[sceneId].hud.elements
        .filter((el) => {
        return ((el.type === 'button' || el.type === 'icon') &&
            el.action.type === 'change_scene');
    })
        .forEach((button) => {
        const target = button.action
            .targetScene;
        targets.add(target);
    });
    // Find all 3D object and Surface click actions that point to other scenes
    sceneGlObjects
        .filter((g) => { var _a; return ((_a = g.action) === null || _a === void 0 ? void 0 : _a.type) === 'change_scene'; })
        .forEach((glObject) => {
        const target = glObject.action['targetScene'];
        targets.add(target);
    });
    // Find all 3D animation end actions that point to other scenes
    sceneGlObjects
        .filter((g) => {
        var _a;
        if (g.type === 'model') {
            if (((_a = g.animation) === null || _a === void 0 ? void 0 : _a.onEnd.type) === 'change_scene') {
                return true;
            }
        }
        return false;
    })
        .forEach((glObject) => {
        const target = glObject['animation']['onEnd']['targetScene'];
        targets.add(target);
    });
    // Find all video end actions that point to other scenes
    sceneGlObjects
        .filter((g) => {
        var _a;
        if (g.type === 'surface') {
            if (g.textureType === 'video' &&
                ((_a = g.onEnd) === null || _a === void 0 ? void 0 : _a.type) === 'change_scene') {
                return true;
            }
        }
        return false;
    })
        .forEach((glObject) => {
        const target = glObject['onEnd']['targetScene'];
        targets.add(target);
    });
    // Find all audio end actions that point to other scenes
    if (((_b = (_a = hlScene.audio) === null || _a === void 0 ? void 0 : _a.onEnd) === null || _b === void 0 ? void 0 : _b.type) === 'change_scene') {
        targets.add(hlScene.audio.onEnd['targetScene']);
    }
    // Find all timer end actions that point to other scenes
    if (((_d = (_c = hlScene.timer) === null || _c === void 0 ? void 0 : _c.onEnd) === null || _d === void 0 ? void 0 : _d.type) === 'change_scene') {
        targets.add(hlScene.timer.onEnd['targetScene']);
    }
    //Return array with exit targets
    return Array.from(targets).map((t) => {
        return [t, hololink.scenes[t]];
    });
}
/// Scene manager manage scenes state. It is here we show, pause and resume scenes.
/// When a new scene is shown all it's children are fetched and loaded, if those are not present.
/**
 * Creates the model manager factory. When initialized the first scene is loaded
 * @param hololink Hololink document
 * @param renderer Babylon render
 * @returns Scene controls
 */
export const ModelManager = (hololink, renderer) => {
    let loadedScenes = [];
    const contentManager = renderer.contentManager;
    renderer.contentManager.loadGround(hololink);
    /// This inital call loads the first scene
    return (renderer.contentManager
        .loadScenes([
        {
            sceneId: hololink.startScene,
            hlscene: hololink.scenes[hololink.startScene],
        },
    ])
        /// After the inital scene is loaded we load all children
        .then(() => {
        loadedScenes.push(hololink.startScene);
        return {
            hololink,
            resumeScene: (sceneId) => renderer.resumeScene(sceneId),
            pauseScene: (sceneId) => renderer.pauseScene(sceneId),
            resetScene: (sceneId) => renderer.resetScene(sceneId),
            prepareScene: (sceneId) => {
                // renderer.showScene(sceneId);
                // contentManager.showScene(sceneId);
                const exits = getExits(sceneId, hololink);
                /// Find all scenes that can be navigated to from current scene
                const scenesToLoad = exits
                    .filter(([id, scene]) => {
                    return loadedScenes.indexOf(id) === -1;
                })
                    .filter(([id, scene]) => scene !== undefined) // The editor might send us change_scene actions with no target
                    .map(([exitId, scene]) => {
                    return {
                        sceneId: exitId,
                        hlscene: scene,
                    };
                });
                // This one should first be updated after scenes are succesfull loaded
                // One solution could be to make this function async await the loadScenes and then push them
                loadedScenes.push(...scenesToLoad.map((s) => s.sceneId));
                // It seems we should call the showScene of the renderer like for resumeScene and pauseScene above
                // renderer.showScene(sceneId);
                return fromPromise(contentManager.loadScenes(scenesToLoad));
            },
            startScene: (sceneId) => {
                contentManager.showScene(sceneId);
                renderer.showScene(sceneId);
            },
        };
    }));
};
