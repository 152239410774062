import axios from 'axios';
const Settings = {
    domain: process.env.API_URL,
};
const ipApi = 'https://api.ipify.org?format=json';
const geoApi = 'https://ipgeolocation.abstractapi.com/v1/?api_key=f5bdd1da32da4523a76c8fe9ac3c32bf&ip_address=';
let uuid;
/**
 * Called after the hololink is loaded
 * @param hololink
 */
export const InitializeStatistics = (hololink) => {
    const statisticsController = StatisticsController();
    statisticsController.createSession(hololink);
    statisticsController
        .getIP()
        .then((response) => statisticsController.getGeo(response.data.ip))
        .then((response) => {
        // we delete the ip_address due to GDPR
        delete response.data.ip_address;
        const event = {
            timestampClient: new Date(),
            type: 'App started',
            userInfo: {
                geo: response.data,
                agent: window.navigator.userAgent,
            },
        };
        statisticsController.logEvent(event);
    })
        .catch((error) => {
        error.log('Error fetching location of app started event:', error);
    });
    /// Listen to App closed
    window.addEventListener('beforeunload', () => {
        statisticsController.logEvent({
            timestampClient: new Date(),
            type: 'App closed',
        });
        return null;
    });
    return statisticsController;
};
export const StatisticsController = () => {
    return {
        createSession: (hololink) => {
            const viewerMode = process.env.STATE === 'published' ? 'published' : 'preview';
            axios({
                method: 'post',
                url: `${Settings.domain}/statistics`,
                data: {
                    hololinkId: hololink._id,
                    viewerMode: viewerMode,
                },
            }).then((response) => {
                uuid = response.data.uuid;
            }, (error) => {
                console.log('Error setting UUID:', error);
            });
        },
        logEvent: (event) => {
            axios({
                method: 'post',
                url: `${Settings.domain}/statistics/event`,
                data: {
                    uuid,
                    event,
                },
            }).then((response) => { }, (error) => {
                console.log('Error loggin analytics event:', error);
            });
        },
        getIP: () => {
            return axios.get(ipApi);
        },
        getGeo: (ip) => {
            return axios.get(`${geoApi}${ip}`);
        },
    };
};
