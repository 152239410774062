export const CanvasElement = (size) => {
    const canvas = document.createElement('canvas');
    canvas.width = size.width;
    canvas.height = size.height;
    const context = canvas.getContext('2d', {
        alpha: false,
    });
    return {
        getElement: () => canvas,
        getContext: () => context,
        draw: (element) => {
            let width = element instanceof HTMLVideoElement
                ? element.videoWidth
                : element.width;
            let height = element instanceof HTMLVideoElement
                ? element.videoHeight
                : element.height;
            context.drawImage(element, 0, 0, width, height);
        },
        getImageData: (whPx = 0) => context.getImageData(0, whPx, canvas.width, canvas.height - whPx),
        destroy: () => canvas.parentElement.removeChild(canvas),
    };
};
