var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';
import { BehaviorSubject, Subject } from 'rxjs';
import { AssetLoader } from './asset.loader';
import { AssetTaskHandler, AssetTasks } from './task.handlers';
import { createGroundPlane } from '../../../../library-babylon/dist/mesh.functions';
/**
 * Content Manager handles how assets are loaded
 * @param babylonScene
 * @param content
 * @param events$
 */
export const ContentManager = (babylonScene, content, events$) => {
    const assetManager = new AssetsManager(babylonScene);
    assetManager.useDefaultLoadingScreen = false;
    let progress$ = new Subject();
    const _sceneChanged$ = new BehaviorSubject(null);
    let _isTracked = false;
    /**
     * Enables a given scene, and disables all other
     * @param sceneId
     */
    const showScene = (sceneId) => {
        _sceneChanged$.next(sceneId);
        const contentNodes = content.getChildren(null, true);
        contentNodes
            .filter((node) => Boolean(node.metadata))
            .forEach((node) => {
            node.setEnabled(node.metadata['sceneId'] === sceneId);
        });
    };
    const taskHandler = AssetTasks(babylonScene, events$);
    const loadGround = (hololink) => {
        var _a;
        if (!((_a = hololink === null || hololink === void 0 ? void 0 : hololink.settings) === null || _a === void 0 ? void 0 : _a.ground))
            return;
        const arRootNode = babylonScene.getTransformNodeById('arRootNode');
        const ground = createGroundPlane(babylonScene)(hololink.settings.ground);
        ground.parent = arRootNode;
        const engine = babylonScene.getEngine();
        ground.onBeforeRenderObservable.add(() => {
            engine.setColorWrite(false);
        });
        ground.onAfterRenderObservable.add(() => {
            engine.setColorWrite(true);
        });
    };
    /**
     * Loads an array of scene models
     * @param scenes
     * @returns void
     */
    const loadScenes = (scenes) => __awaiter(void 0, void 0, void 0, function* () {
        const assetLoader = AssetLoader(assetManager);
        /// An object mapped with sceneId and its assets tasks
        const scenesTasks = scenes.map((scene) => {
            return {
                sceneId: scene.sceneId,
                meshTasks: scene.hlscene.glObjects
                    .filter((gl) => gl.type === 'model')
                    .map((gl) => {
                    return {
                        glModel: gl,
                        task: assetLoader.loadGlObject(gl),
                    };
                }),
                surfaceTask: scene.hlscene.glObjects
                    .filter((gl) => gl.type === 'surface')
                    .map((gl) => {
                    return {
                        glSurface: gl,
                        task: assetLoader.loadGlObject(gl),
                    };
                }),
                geometryTasks: scene.hlscene.glObjects
                    .filter((gl) => gl.type === 'geometry')
                    .map((gl) => {
                    return {
                        glTemplate: gl,
                        task: assetLoader.loadGlObject(gl),
                    };
                }),
                audioTask: scene.hlscene.audio
                    ? {
                        audio: scene.hlscene.audio,
                        task: assetLoader.loadBinary(scene.hlscene.audio.url),
                    }
                    : {},
            };
        });
        scenesTasks.map(AssetTaskHandler(taskHandler, events$));
        assetManager.onProgress = (remainingCount, totalCount, lastFinishedTask) => {
            progress$.next({
                sceneCount: scenes.length,
                totalCount,
                remainingCount,
            });
        };
        assetManager.onFinish = (tasks) => {
            progress$.next();
            progress$.complete();
        };
        yield assetLoader.loadAssets();
    });
    return {
        loadScenes,
        loadGround,
        showScene,
        sceneChanged$: _sceneChanged$.asObservable(),
        updateTracking: (isTracked) => (_isTracked = isTracked),
        progress$: progress$.asObservable(),
    };
};
