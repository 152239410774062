var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// import { AbstractMesh, ActionManager, AssetsManager, Color4, Engine, FreeCamera, HemisphericLight, Matrix, Node, Scene, Tools, TransformNode, Vector3 } from '@babylonjs/core';
import { CubeTexture, FreeCamera, GlowLayer, HemisphericLight, } from '@babylonjs/core';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { Engine } from '@babylonjs/core/Engines/engine';
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';
import { Tools } from '@babylonjs/core/Misc/tools';
import { Subject } from 'rxjs';
import { ContentManager } from './content-handling/content.manager';
import { getAdvancedDynamicTexture } from './content-handling/gui.functions';
import '@babylonjs/core/Debug/debugLayer'; // Augments the scene with the debug methods
//import '@babylonjs/inspector'; // Injects a local ES6 version of the inspector to prevent
import { MarkerCamera } from './scene-types/marker.camera';
import { GetSceneTypesInHololinks } from '../../../library/dist/utilities/hololink.utils';
import { switchDefaultAssert } from '../../../library/dist/utilities/switch-case.guard';
import { Scene } from '@babylonjs/core/scene';
import { ThreeSixtyCamera } from './scene-types/three-sixty.camera';
export const getBaseNode = (node) => {
    var _a;
    if ((_a = node.metadata) === null || _a === void 0 ? void 0 : _a.base) {
        return node;
    }
    const nodeParent = node['_parentNode'];
    if (nodeParent) {
        return getBaseNode(nodeParent);
    }
    else {
        return null;
    }
};
/// This loads babylon and sets up the scene
export const BabylonRenderer = (canvas, hololink) => __awaiter(void 0, void 0, void 0, function* () {
    // in some cases, models will still be loading
    // when a scene is opened. therefore we store
    // the current scene id when it changes,
    // so that the load task success handler can
    // check, even if the scene has changed after
    // loading started.
    // Initialize engine and scene
    // Option parameter are added because in enables us to take screenshot of scene
    const engine = new Engine(canvas, true, {
        preserveDrawingBuffer: true,
        stencil: true,
    });
    const scene = new Scene(engine);
    scene.clearColor = new Color4(0, 0, 0, 0);
    const sceneTypes = GetSceneTypesInHololinks(hololink);
    /// Light
    const light = new HemisphericLight('light', new Vector3(1, 1, 0), scene);
    const defaultEnv = new CubeTexture('https://assets.babylonjs.com/environments/environmentSpecular.env', scene, null, null, null, () => {
        defaultEnv.name = 'defaultEnv';
        scene.environmentTexture = defaultEnv;
    });
    const gl = new GlowLayer('glow-layer', scene);
    gl.intensity = 1;
    const camera = scene.getCameraById('marker-camera');
    scene.actionManager = new ActionManager(scene);
    /// TODO fix the environment
    // scene.createDefaultEnvironment({
    //     createSkybox: false,
    // });
    const assetManager = new AssetsManager(scene);
    assetManager.useDefaultLoadingScreen = false;
    let content = new TransformNode('content', scene);
    content.setEnabled(false);
    const events$ = new Subject();
    const contentManager = ContentManager(scene, content, events$);
    window.addEventListener('resize', () => {
        engine.resize();
    });
    // scene.debugLayer.show({
    //     embedMode: true,
    // });
    // Click action (click on model)
    // const setupRayCasting = () => {
    //     scene.onPointerDown = function castRay() {
    //         const ray = scene.createPickingRay(
    //             scene.pointerX,
    //             scene.pointerY,
    //             Matrix.Identity(),
    //             camera
    //         );
    //         const hit = scene.pickWithRay(ray);
    //         if (hit.pickedMesh) {
    //             const baseNode: AbstractMesh = getBaseNode(hit.pickedMesh);
    //             if (baseNode.metadata?.action) {
    //                 events$.next(baseNode.metadata.action);
    //             }
    //         }
    //     };
    // };
    const handleAnimations = (action, sceneId) => {
        const contentNodes = content.getChildren(null, true);
        contentNodes.forEach((node) => {
            var _a;
            if (node.metadata) {
                const animation = node.metadata.animation;
                if (sceneId === node.metadata['sceneId'] && animation) {
                    switch (action) {
                        case 'start':
                            animation.isStarted
                                ? animation.play()
                                : animation.start(node.metadata.animation.loopAnimation);
                            break;
                        case 'pause':
                            animation.pause();
                            break;
                        case 'stop':
                            animation.stop();
                            break;
                        case 'restart':
                            animation.stop();
                            animation.restart();
                            break;
                        default:
                            animation.stop();
                    }
                }
                (_a = node.metadata.extraAnimations) === null || _a === void 0 ? void 0 : _a.forEach((animation) => {
                    switch (action) {
                        case 'start':
                            animation.isStarted
                                ? animation.play()
                                : animation.start(animation.loopAnimation);
                            break;
                        case 'pause':
                            animation.pause();
                            break;
                        case 'stop':
                            animation.stop();
                            break;
                        case 'restart':
                            animation.stop();
                            animation.start(animation.loopAnimation);
                            break;
                        default:
                            animation.stop();
                    }
                });
                const video = sceneId === node.metadata['sceneId'] && node.metadata.video;
                if (video) {
                    // here we are setting the actual value of muted - we initialize videos with muted = true
                    video.muted = node.metadata.muted;
                    switch (action) {
                        case 'start':
                            video.play();
                            break;
                        case 'pause':
                            video.pause();
                            break;
                        case 'stop':
                            video.pause();
                            video.currentTime = 0;
                            break;
                        case 'restart':
                            video.play();
                            break;
                        default:
                            video.pause();
                    }
                }
            }
        });
        return;
    };
    const handleAudio = (action, sceneId) => {
        if (scene.sounds) {
            scene.sounds.forEach((sound) => {
                if (sound.name === sceneId) {
                    switch (action) {
                        case 'start':
                            sound.play();
                            break;
                        case 'pause':
                            sound.pause();
                            break;
                        case 'stop':
                            sound.stop();
                            break;
                        default:
                            sound.stop();
                            break;
                    }
                }
                else {
                    sound.stop();
                }
            });
        }
    };
    /// Handle visibilty of annotations
    const handleAnnotations = (show, sceneId) => {
        const adt = getAdvancedDynamicTexture(scene);
        adt.rootContainer.children.map((c) => {
            c.metadata.sceneId === sceneId && show
                ? (c.isVisible = true)
                : (c.isVisible = false);
        });
    };
    const resumeScene = (sceneId) => {
        handleAudio('start', sceneId);
        handleAnnotations(true, sceneId);
        return handleAnimations('start', sceneId);
    };
    const pauseScene = (sceneId) => {
        handleAudio('pause', sceneId);
        handleAnnotations(false, sceneId);
        return handleAnimations('pause', sceneId);
    };
    const showScene = (sceneId) => {
        contentManager.showScene(sceneId);
        if (hololink.scenes[sceneId].type === '360') {
            content.setEnabled(true);
        }
        return handleAnimations('restart', sceneId);
    };
    const screenshot = (size) => {
        return Tools.CreateScreenshotAsync(engine, camera, {
            width: size.width,
            height: size.height,
        });
    };
    const resetScene = (sceneId) => {
        handleAudio('stop', sceneId);
        return handleAnimations('stop', sceneId);
    };
    return {
        scene: () => scene,
        loadCameras: () => {
            /// Create the camera we need for the scene types in the holink
            const cameras = sceneTypes.map((t, i) => {
                let camera;
                switch (t) {
                    case 'marker':
                        camera = MarkerCamera(scene)(canvas);
                        break;
                    case '360':
                        camera = ThreeSixtyCamera(scene)(canvas);
                        break;
                    case 'page':
                    case 'modelview':
                        camera = new FreeCamera('none-camera', new Vector3(0, 0, 0), scene);
                        break;
                    default:
                        switchDefaultAssert(t);
                }
                /// Set the first camera to active
                if (i === 1) {
                    scene.setActiveCameraByName(camera.name);
                }
                return camera;
            });
            //setupRayCasting();
        },
        start: () => {
            engine.runRenderLoop(() => {
                scene.render();
            });
        },
        resumeScene,
        pauseScene,
        showScene,
        resetScene,
        screenshot,
        startVideos: () => {
            const contentNodes = content.getChildren(null, true);
            const videos = contentNodes.filter((c) => { var _a; return ((_a = c.metadata) === null || _a === void 0 ? void 0 : _a.textureType) === 'video' && c.metadata.video; });
            const videoElms = videos.forEach((v) => {
                v.metadata.video.play();
                v.metadata.video.pause();
                v.metadata.video.currentTime = 0;
            });
        },
        events$,
        contentManager,
    };
});
