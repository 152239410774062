var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Timer } from '../timer';
import { WorkerSettings } from '../worker.settings';
import { DetectorHandler } from './detector.handler';
export const Detectors = () => __awaiter(void 0, void 0, void 0, function* () {
    /// Number of detector
    const COUNT = 1;
    /// Delay between detectors in ms
    const DELAY = 200;
    const detectors = [DetectorHandler(), DetectorHandler()];
    yield Promise.all(detectors.map((d) => d.load()));
    yield Promise.all(detectors.map((d) => d.init()));
    detectors[0].reset(3, 0, 3, 0.005, 3, 1);
    detectors[1].reset(3, 0, 3, 0.001, 10, 10);
    const detectorStatuses = detectors.map((d) => 'ready');
    const detectorResult = new BehaviorSubject(null);
    let bestDiagonalRatio = 100;
    /// Clousure nesesary to keep track of index internally
    const processFrame = (index, imageData, timestamp) => (resultCallback) => {
        detectorStatuses[index] = 'busy';
        detectors[index]
            .processFrame(imageData, timestamp)
            .then(detectors[index].generateResult)
            .then((r) => {
            /// Only update tracking state if we found it.
            /// Let the tracker handle tracking lost
            if (r.isTracked) {
                /// Filter out all results have a proportional bigger diffrence in diagonal ratio
                const best = closetToOne(bestDiagonalRatio, r.validation.diagonalRatio);
                if (best == r.validation.diagonalRatio) {
                    bestDiagonalRatio = r.validation.diagonalRatio;
                    detectorResult.next(r);
                    resultCallback(r);
                }
            }
            detectorStatuses[index] = 'ready';
        });
    };
    const timer = Timer();
    const detectorInterval = WorkerSettings.detectorFrequency;
    timer.set({ name: 'detector', time: detectorInterval });
    let detectorTimerReady = true;
    detectorResult
        .pipe(filter((r) => r != null && r.isTracked))
        .subscribe(() => {
        detectorTimerReady = false;
    });
    let _mode;
    const updateMode = (mode) => {
        /// Reset bestDiagonalRatio when we go from updating to searching
        if (_mode === 'updating' && mode === 'searching') {
            bestDiagonalRatio = 100;
        }
        _mode = mode;
    };
    updateMode('searching');
    /// index 0 = quick detector
    /// index 1 = robust detector
    const isReady = (index) => {
        if (index == -1)
            return false;
        if (_mode === 'searching')
            return true;
        if (_mode === 'updating' && index === 1)
            return detectorTimerReady;
        // return (_mode === 'searching') ? true : detectorTimerReady;
    };
    const getIndex = () => {
        if (_mode === 'searching') {
            /// Get first ready dectector
            return detectorStatuses.findIndex((w) => w === 'ready');
        }
        if (_mode === 'updating' && detectorStatuses[1] === 'ready') {
            return 1;
        }
        return -1;
    };
    const closetToOne = (...args) => {
        const diffArray = args.map((v) => Math.abs(1 - v));
        const lowest = Math.min(...diffArray);
        return args[diffArray.indexOf(lowest)];
    };
    return {
        init: (height, width) => __awaiter(void 0, void 0, void 0, function* () {
            timer.reset();
            return Promise.all(detectors.map((d) => d.setFrameSrc(height, width)));
        }),
        updateMode,
        setMarker: (imageTarget) => __awaiter(void 0, void 0, void 0, function* () {
            const rects = yield Promise.all(detectors.map((d) => d.setMarker(imageTarget)));
            console.log('rects: ', rects);
            /// All detectors create the same rect, we only need to return one
            return rects[0];
        }),
        getReadyDetectorIndex: () => detectorStatuses.findIndex((w) => w === 'ready'),
        processFrame: (imageData, timestamp) => {
            timer.update();
            if (timer.alert('detector')) {
                detectorTimerReady = true;
                timer.set({ name: 'detector', time: detectorInterval });
            }
            const index = getIndex();
            const processFrameFn = processFrame(index, imageData, timestamp);
            return {
                isReady: isReady(index),
                result: (result) => processFrameFn(result),
            };
        },
        result$: detectorResult
            .asObservable()
            .pipe(),
        setParams: (index, params) => __awaiter(void 0, void 0, void 0, function* () { return detectors[index].reset(...params); }),
    };
});
