import { AdvancedDynamicTexture, Control, Image, Rectangle, TextBlock, } from '@babylonjs/gui';
import { GameState } from './game-state';
export const UI = (gameState) => {
    const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('UI');
    /// Count down
    const bgImage = new Image('bg', 'images/alternative_bg-tall.jpg');
    bgImage.width = '100%';
    bgImage.height = '100%';
    bgImage.isVisible = false;
    advancedTexture.addControl(bgImage);
    const countDown = new TextBlock();
    countDown.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    countDown.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    countDown.paddingLeft = '5px';
    countDown.paddingTop = '5px';
    countDown.color = '#f6e085';
    countDown.shadowBlur = 20;
    countDown.shadowColor = '#f6e085';
    countDown.fontSize = 50;
    countDown.width = '100%';
    countDown.isVisible = false;
    advancedTexture.addControl(countDown);
    /* PLAY PANEL */
    const playerLivesTxt = new TextBlock();
    playerLivesTxt.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    playerLivesTxt.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    playerLivesTxt.paddingLeft = '10px';
    playerLivesTxt.paddingTop = '10px';
    playerLivesTxt.color = '#E50D7B';
    playerLivesTxt.fontSize = 25;
    playerLivesTxt.width = '100%';
    playerLivesTxt.shadowBlur = 5;
    playerLivesTxt.shadowColor = '#E50D7B';
    playerLivesTxt.isVisible = false;
    advancedTexture.addControl(playerLivesTxt);
    let score = 0;
    const scoreTxt = new TextBlock();
    scoreTxt.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    scoreTxt.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    scoreTxt.paddingRight = '10px';
    scoreTxt.paddingTop = '10px';
    scoreTxt.color = '#f6e085';
    scoreTxt.fontSize = 25;
    scoreTxt.width = '100%';
    scoreTxt.shadowBlur = 5;
    scoreTxt.shadowColor = '#f6e085';
    scoreTxt.text = '0';
    scoreTxt.isVisible = false;
    advancedTexture.addControl(scoreTxt);
    /* Finish panel */
    const finishRect = new Rectangle();
    finishRect.height = '55%';
    finishRect.width = '90%';
    finishRect.cornerRadius = 20;
    finishRect.color = '#E0E2EA';
    finishRect.thickness = 0;
    //finishRect.background = '#0E173A';
    finishRect.isVisible = false;
    advancedTexture.addControl(finishRect);
    const gameOverTxt = new TextBlock();
    gameOverTxt.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    gameOverTxt.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    gameOverTxt.paddingTop = '10px';
    gameOverTxt.color = '#E0E2EA';
    gameOverTxt.fontSize = 35;
    gameOverTxt.width = '100%';
    gameOverTxt.text = 'GAME OVER';
    gameOverTxt.shadowBlur = 10;
    gameOverTxt.shadowColor = '#E0E2EA';
    finishRect.addControl(gameOverTxt);
    const finalScoreTxt = new TextBlock();
    finalScoreTxt.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    finalScoreTxt.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    finalScoreTxt.color = '#E0E2EA';
    finalScoreTxt.paddingTop = '60px';
    finalScoreTxt.fontSize = 25;
    finalScoreTxt.width = '100%';
    finalScoreTxt.shadowBlur = 10;
    finalScoreTxt.shadowColor = '#E0E2EA';
    finishRect.addControl(finalScoreTxt);
    const screenShotMsg = new TextBlock();
    screenShotMsg.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    screenShotMsg.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    screenShotMsg.color = '#E0E2EA';
    screenShotMsg.paddingTop = '110px';
    screenShotMsg.fontSize = 20;
    screenShotMsg.width = '100%';
    screenShotMsg.shadowBlur = 10;
    screenShotMsg.shadowColor = '#E0E2EA';
    finishRect.addControl(screenShotMsg);
    const button = new TextBlock();
    button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    button.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    button.shadowBlur = 10;
    button.shadowColor = '#f6e085';
    button.isVisible = false;
    button.height = '60px';
    button.text = 'CLICK HERE TO TRY AGAIN';
    button.paddingBottom = '10px';
    button.fontSize = 25;
    button.color = '#f6e085';
    button.onPointerUpObservable.add(() => {
        gameState.next();
    });
    finishRect.addControl(button);
    return {
        display: (gameState) => {
            switch (gameState) {
                case GameState.Start:
                    countDown.isVisible = true;
                    playerLivesTxt.isVisible = false;
                    scoreTxt.isVisible = false;
                    finishRect.isVisible = false;
                    bgImage.isVisible = false;
                    button.isVisible = false;
                    break;
                case GameState.Play:
                    countDown.isVisible = false;
                    playerLivesTxt.isVisible = true;
                    scoreTxt.isVisible = true;
                    finishRect.isVisible = false;
                    bgImage.isVisible = false;
                    button.isVisible = false;
                    break;
                case GameState.Finish:
                    countDown.isVisible = false;
                    playerLivesTxt.isVisible = false;
                    scoreTxt.isVisible = true;
                    finishRect.isVisible = true;
                    bgImage.isVisible = true;
                    screenShotMsg.text =
                        'Take a screenshot of your\nhighscore and win prizes at the\nMaster Blaster stand';
                    finalScoreTxt.text = 'YOUR SCORE: ' + score;
                    setTimeout(() => {
                        button.isVisible = true;
                    }, 3000);
                    break;
            }
        },
        updateScore: (points) => {
            score += points;
            scoreTxt.text = `${score}`;
        },
        resetScore: () => {
            score = 0;
            scoreTxt.text = `${score}`;
        },
        updateLives: (lives) => {
            playerLivesTxt.text = '❤'.repeat(lives);
        },
        countDown: (count) => {
            countDown.text = count;
        },
    };
};
