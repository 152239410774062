import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
export const MarkerCamera = (scene) => (canvas) => {
    const camera = new FreeCamera('marker-camera', new Vector3(0, 0, 0), scene);
    camera.minZ = 0.01;
    /// Aproximate FOV from focal length
    /// https://learnopencv.com/approximate-focal-length-for-webcams-and-cell-phone-cameras/
    camera.fov = 2 * Math.atan(canvas.height / (2 * canvas.width));
    return camera;
};
