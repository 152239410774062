import { defaultSplash } from '../../../library/dist/utilities/defaultSplash';
export const holoDocLoaded = new Event('hololink-doc-loaded');
// Runs when hololink document has loaded
document.addEventListener('hololink-doc-loaded', (e) => {
    // Show loader
    const hl = window['hl'];
    const style = (hl === null || hl === void 0 ? void 0 : hl.customLoader)
        ? hl === null || hl === void 0 ? void 0 : hl.customLoader
        : defaultSplash;
    // Set loader colors
    const root = document.querySelector(':root');
    //Set favicon, title & T&C
    document
        .getElementById('favicon')
        .setAttribute('href', style.favicon !== undefined
        ? style.favicon
        : defaultSplash.favicon);
    document.getElementById('splash-terms-text').innerText =
        style.tcText !== undefined ? style.tcText : defaultSplash.tcText;
    document.getElementById('loading-terms-text').innerText =
        style.tcText !== undefined ? style.tcText : defaultSplash.tcText;
    const splashTC = document.getElementById('splash-terms-link');
    const loadingTC = document.getElementById('loading-terms-link');
    const spinner = document.getElementById('spinner');
    splashTC.innerText =
        style.tcLinkText !== undefined
            ? style.tcLinkText
            : defaultSplash.tcLinkText;
    loadingTC.innerText =
        style.tcLinkText !== undefined
            ? style.tcLinkText
            : defaultSplash.tcLinkText;
    splashTC.setAttribute('href', style.tcLink !== undefined ? style.tcLink : defaultSplash.tcLink);
    loadingTC.setAttribute('href', style.tcLink !== undefined ? style.tcLink : defaultSplash.tcLink);
    spinner.style.backgroundImage =
        `url('${style.loader.spinnerImage}')` ||
            `url('${defaultSplash.loader.spinnerImage}')`;
    spinner.style.width =
        `${style.loader.spinnerImageSize}px` ||
            `${defaultSplash.loader.spinnerImageSize}px`;
    spinner.style.height =
        `${style.loader.spinnerImageSize}px` ||
            `${defaultSplash.loader.spinnerImageSize}px`;
    if (style.loader.spinnerImage === defaultSplash.loader.spinnerImage) {
        spinner.style.animation = 'rotation 30s ease-in 0s 2';
    }
    document.title = style.title;
    root.style.cssText =
        root.style.cssText +
            `
            --loader-text-color: ${style.loader.textColor};
            --loader-bg-color: ${style.loader.bgColor};
            --loader-bg-image: ${style.loader.bgImage
                ? 'url(' + style.loader.bgImage + ')'
                : 'none'};

            --modal-bg-color: ${style.loader.modalBgColor};
            --modal-text-color: ${style.loader.modalTextColor};

            --splash-text-color: ${style.splash.textColor};
            --splash-bg-color: ${style.splash.bgColor};
            --splash-bg-image: ${style.splash.bgImage
                ? 'url(' + style.splash.bgImage + ')'
                : 'none'};
        `;
    // Set start text
    document.getElementById('btn-start').textContent = style.splash.tapText;
    // Set start text
    document.getElementById('loading-text').textContent =
        style.loader.loadingText;
    // Set watermark text
    document.getElementById('watermark').innerHTML = 'Powered by Hololink';
    // Show loader
    document.getElementById('loading').style.setProperty('opacity', '1');
}, false);
