import { Angle } from '@babylonjs/core';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
/**
 * First argument is the glModel object, and the second argument is the mesh to add those transformations
 */
export const updateMeshFromGlModelTransform = (glModel) => (content) => {
    var _a;
    content.position = new Vector3(glModel.transform.position[0], glModel.transform.position[1], glModel.transform.position[2]);
    const scale = new Vector3(glModel.transform.scaling[0], glModel.transform.scaling[1], glModel.transform.scaling[2]);
    content.scaling = scale.scale((_a = glModel.transform.uniformScale) !== null && _a !== void 0 ? _a : 1);
    content.rotation = Vector3.FromArray(glModel.transform.rotation.map((r) => Angle.FromDegrees(r).radians()));
    console.log('content.rotation: ', content.rotation);
    return content;
};
/**
 * Disable a mesh
 */
export const disableTransform = (m) => {
    m.setEnabled(false);
    return m;
};
