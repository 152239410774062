export const Timer = () => {
    let _lastTotalTime = 0;
    let deltaTime = 0;
    let timers = [];
    let offset = 0;
    return {
        reset: () => {
            offset = performance.now();
        },
        update: () => {
            const totalTime = performance.now() - offset;
            deltaTime = totalTime - _lastTotalTime;
            _lastTotalTime = totalTime;
            /// Update timers
            timers = timers.map((t) => {
                return {
                    name: t.name,
                    time: t.time - deltaTime,
                };
            });
        },
        set: (timer) => {
            timers.push(timer);
        },
        get: (name) => timers.find((t) => t.name === name),
        cancel: (name) => {
            timers = timers.filter(n => n.name !== name);
        },
        alert: (name) => {
            const timer = timers.find((t) => t.name === name);
            if ((timer === null || timer === void 0 ? void 0 : timer.time) < 0) {
                timers = timers.filter((t) => t.name !== name);
                return true;
            }
            return false;
        },
    };
};
