var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
export const SDKSettings = () => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield axios.get(`https://assets.hololink.io/games/master-blasteroids/master-blasteroids.json`);
    const settings = resp.data;
    // return {
    //     maxAngleAlpha: settings.maxAngleAlpha ?? 45,
    //     maxAngleBeta: settings.maxAngleBeta ?? 45,
    // };
    return {
        maxAngleAlpha: 45,
        maxAngleBeta: 45,
        startRate: 45,
        rateIncrease: 1,
        lives: 3,
    };
});
