var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { Detectors } from './detector/detectors';
import { PoseHandler } from './pose/pose.handler';
import { Timer } from './timer';
import { TrackerHandler } from './tracker/tracker.handler';
import { WorkerSettings } from './worker.settings';
export const WorkerHandler = () => __awaiter(void 0, void 0, void 0, function* () {
    /// Initialize workers
    // const detectorHandler = DetectorHandler();
    const detectors = yield Detectors();
    const poseHandler = yield PoseHandler();
    const trackerHandler = yield TrackerHandler();
    // await detectorHandler.load();
    yield poseHandler.load();
    yield trackerHandler.load();
    console.log('Workers loaded');
    // detectorHandler.init();
    trackerHandler.init();
    /// Get video canvas
    const videoCanvas = document.getElementById('videoCanvas');
    const videoCanvasCtx = videoCanvas.getContext('2d', {
        alpha: false,
    });
    /// Internal states
    let rect;
    const result = new BehaviorSubject({
        isTracked: false,
    });
    let processFrameArgs;
    /// Tracking states
    const timer = Timer();
    let trackingId = '';
    let modelMatrix = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    let isTracked = false;
    /// We go change tracking state
    result
        .pipe(pairwise(), filter(([r1, r2]) => {
        return r1.isTracked != r2.isTracked;
    }))
        .subscribe(([r1, r2]) => {
        if (r2.type === 'Tracker' && !r2.isTracked) {
            timer.set({
                name: 'TrackingLost',
                time: WorkerSettings.trackingLostTimeout,
            });
        }
        if (r2.isTracked) {
            timer.cancel('TrackingLost');
        }
    });
    const trackerFn = () => {
        let trackerReady = false;
        return {
            reset: (homography, preppedScene, features, height, width) => __awaiter(void 0, void 0, void 0, function* () {
                yield trackerHandler.reset(homography, preppedScene, features, height, width, rect.width, rect.height);
                trackerReady = true;
            }),
            isReady: () => trackerReady,
            processFrame: (imageData, timestamp) => __awaiter(void 0, void 0, void 0, function* () {
                trackerReady = false;
                yield trackerHandler.processFrame(imageData, timestamp);
                const result = yield trackerHandler.generateResult();
                trackerReady = result.isTracked;
                return result;
            }),
        };
    };
    const tracker = trackerFn();
    /// Refactor
    const getModelMatrix = (homography) => __awaiter(void 0, void 0, void 0, function* () {
        yield poseHandler.updatePose(homography);
        const mm = yield poseHandler.getModelMatrix();
        return {
            mm,
        };
    });
    let _height;
    let _width;
    let imageTargetWidth;
    let imageTargetHeight;
    // let detectorRun = true;
    let detectorFrame;
    return {
        init: (height, width) => __awaiter(void 0, void 0, void 0, function* () {
            _height = height;
            _width = width;
            // await detectorHandler.setFrameSrc(height, width);
            yield detectors.init(height, width);
            yield poseHandler.init(height, width, rect);
            timer.reset();
        }),
        /// NEVER BLOK THIS LOOP!
        processFrame: (imageData, totalTime) => {
            timer.update();
            if (timer.alert('TrackingLost')) {
                isTracked = false;
            }
            return new Promise((resolve, reject) => {
                processFrameArgs = {
                    imageData,
                    timestamp: totalTime,
                };
                const detectorProcessFrame = detectors.processFrame(processFrameArgs.imageData, processFrameArgs.timestamp);
                if (detectorProcessFrame.isReady) {
                    detectorFrame = imageData;
                    detectorProcessFrame.result((detectorResult) => {
                        result.next(detectorResult);
                        if (detectorResult.isTracked) {
                            detectors.updateMode('updating');
                            tracker.reset(detectorResult.homography, detectorFrame, detectorResult.features, _height, _width);
                        }
                    });
                }
                /// Tracker
                if (tracker.isReady()) {
                    const frame = processFrameArgs.imageData;
                    tracker
                        .processFrame(processFrameArgs.imageData, processFrameArgs.timestamp)
                        .then((trackerResult) => {
                        result.next(trackerResult);
                        detectors.updateMode(trackerResult.isTracked
                            ? 'updating'
                            : 'searching');
                        return getModelMatrix(trackerResult.homography);
                    })
                        .then((res) => {
                        videoCanvasCtx.putImageData(frame, 0, 0);
                        resolve({
                            modelMatrix: res.mm,
                            isTracked: true,
                        });
                    });
                }
                /// We need to draw video also if video is not tracked
                if (!result.value.isTracked) {
                    videoCanvasCtx.putImageData(processFrameArgs.imageData, 0, 0);
                    resolve({
                        isTracked: false,
                        modelMatrix: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                    });
                }
            });
        },
        setMarker: (imageTarget) => __awaiter(void 0, void 0, void 0, function* () {
            imageTargetWidth = imageTarget.width;
            imageTargetHeight = imageTarget.height;
            // rect = await detectorHandler.setMarker(imageTarget);
            rect = yield detectors.setMarker(imageTarget);
            console.log('rect: ', rect);
        }),
        destroy: () => {
            // detectorHandler.destroy();
        },
    };
});
