import { isMobile } from '../managers/webcam.manager';
import { openModal } from '../services/modal.service';
export function HandleOrientation(event) {
    let isPortrait;
    // We have different orientation checks when handling orientationChange event
    // and when just asking current orientation
    if (event) {
        //This was an orientation change event so we use this check
        const wasLandscape = window.outerWidth > window.outerHeight;
        isPortrait =
            window.orientation === 0 ||
                window.orientation === 180 ||
                wasLandscape;
        showOrientationMessage(isPortrait);
    }
    else {
        //This was jusk asking for current screen orientation so we use another check
        isPortrait =
            window.orientation === 0 ||
                window.orientation === 180 ||
                window.outerHeight > window.outerWidth;
        showOrientationMessage(isPortrait);
    }
    return isPortrait;
}
function showOrientationMessage(isPortrait) {
    const debug = process.env.STATE === 'debug';
    console.log('debug: ', debug);
    // if (debug) {
    //     startViewer
    // }
    if (isMobile()) {
        if (isPortrait) {
            // if (!viewerStarted) {
            //     startViewer().then();
            //     viewerStarted = true;
            // }
            openModal.hide();
        }
        else {
            openModal.show('We do currently not support viewing hololink in landscape mode. Please return to portrait mode');
        }
    }
    else {
        // startViewer().then();
    }
}
