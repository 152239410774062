// import { BehaviorSubject } from 'rxjs';
// import { filter } from 'rxjs/operators';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const Sensors = () => __awaiter(void 0, void 0, void 0, function* () {
    const isIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/);
    const isMobile = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod/i) !== null;
    // const heading$ = new BehaviorSubject<number>(null);
    // const handler = (e) => {
    //     /// On desktop we set heading to 0 (North)
    //     let heading = 45;
    //     if (isMobile) {
    //         heading = e.webkitCompassHeading || Math.abs(e.alpha - 360);
    //         // document.getElementById(
    //         //     'heading'
    //         // ).innerHTML = `<div>heading is</div>${Math.round(heading)}`;
    //     }
    //     // heading$.next(heading);
    // };
    // const handler = (e: DeviceMotionEvent) => {
    //
    // };
    if (isIOS) {
        try {
            const response = yield DeviceMotionEvent.requestPermission();
            if (response === 'granted') {
                //window.addEventListener('devicemotion', handler, true);
            }
            else {
                /// TODO show not granted erro
                alert('Please allow senors!');
            }
        }
        catch (error) {
            /// TODO show not supported error
            alert('Sensors not supported');
            // console.log('Not suppoerted');
        }
    }
    else {
        //window.addEventListener('deviceorientationabsolute', handler, true);
    }
    // return {
    //     heading$: heading$.asObservable().pipe(filter((h) => h != null)),
    // };
});
