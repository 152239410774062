import PoseWorker from 'worker-loader!./pose.worker';
export const PoseHandler = () => {
    const poseWorker = new PoseWorker();
    let listeners = new Map();
    /// Handle all messages return from  our wasm worker
    poseWorker.onmessage = (event) => {
        if (!listeners.has(event.data.name))
            return;
        listeners.get(event.data.name)(event.data.result);
    };
    return {
        load: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'load',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('load', () => resolve());
            });
        },
        init: (width, height, rect) => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'init',
                    args: [height, width, rect],
                });
                /// Add resolve to listeners as a clousre
                listeners.set('init', () => resolve());
            });
        },
        updatePose: (homography) => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'updatePose',
                    args: [homography],
                });
                /// Add resolve to listeners as a clousre
                listeners.set('updatePose', () => resolve());
            });
        },
        getModelMatrix: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'getModelMatrix',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('getModelMatrix', (modelMatrix) => resolve(modelMatrix));
            });
        },
        getCorners: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'getCorners',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('getCorners', (corners) => resolve(corners));
            });
        },
        getAllResults: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'getAllResults',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('getAllResults', (modelMatrices) => resolve(modelMatrices));
            });
        },
        destroy: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                poseWorker.postMessage({
                    name: 'destroy',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('destroy', () => resolve());
            });
        },
    };
};
