var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
export const PolyHedra = () => __awaiter(void 0, void 0, void 0, function* () {
    const url = 'https://cdn.rawgit.com/BabylonJS/Extensions/master/Polyhedron/polyhedra.js';
    const s = document.createElement('script');
    s.src = url;
    document.head.appendChild(s);
    const polyhedra = [];
    const load = () => {
        return new Promise((resolve, reject) => {
            s.onload = () => {
                for (const p in POLYHEDRA) {
                    const polyhedron = POLYHEDRA[p];
                    polyhedra.push(polyhedron);
                }
                resolve();
            };
        });
    };
    yield load();
    return {
        polyhedra: polyhedra,
        create: (scene, name, index) => {
            const polygon = MeshBuilder.CreatePolyhedron(name, { custom: polyhedra[index], size: 20 }, scene);
            polygon.convertToFlatShadedMesh();
            return polygon;
        },
    };
});
