import { Texture, Vector3, Color4, ParticleSystem } from '@babylonjs/core';
export const Explosion = (name, type, scene) => {
    const explosion = new ParticleSystem(name, 1000, scene);
    //Texture of each particle
    explosion.particleTexture = new Texture('textures/babylon-flare.png', scene);
    explosion.emitter = Vector3.Zero();
    // Colors of all particles
    switch (type) {
        case 'sphere':
            explosion.color1 = new Color4(0.88, 0.59, 0.31);
            explosion.color2 = new Color4(0.31, 0.31, 0.29);
            explosion.colorDead = new Color4(0, 0, 0, 0);
            break;
        case 'cylinder':
            explosion.color1 = new Color4(0.7, 0.8, 1.0, 1.0);
            explosion.color2 = new Color4(0.2, 0.5, 1.0, 1.0);
            explosion.colorDead = new Color4(0, 0, 0.2, 0.0);
            break;
    }
    // Size of each particle (random between...
    explosion.minSize = 0.5;
    explosion.maxSize = 3;
    // Life time of each particle (random between...
    explosion.minLifeTime = type === 'sphere' ? 0.05 : 0.3;
    explosion.maxLifeTime = type === 'sphere' ? 0.5 : 1.5;
    // Emission rate
    explosion.emitRate = type === 'sphere' ? 100000 : 10000;
    /******* Emission Space ********/
    switch (type) {
        case 'sphere':
            explosion.createSphereEmitter(1, 1);
            break;
        case 'cylinder':
            explosion.createCylinderEmitter(1, 1, 0, 0);
            break;
    }
    // Speed
    explosion.minEmitPower = type === 'sphere' ? 10 : 80;
    explosion.maxEmitPower = type === 'sphere' ? 50 : 150;
    explosion.updateSpeed = type === 'sphere' ? 0.01 : 0.005;
    explosion.targetStopDuration = type === 'sphere' ? 0.0333 : 0.01;
    return explosion;
};
