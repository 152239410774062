import { TransformNode, Vector3, Quaternion, Matrix, } from '@babylonjs/core';
import { getAdvancedDynamicTexture } from './content-handling/gui.functions';
export const BabylonTracker = (scene) => (tracker, contentManager) => {
    const adt = getAdvancedDynamicTexture(scene);
    // Initial transform of chilren
    const content = new TransformNode('arRootNode');
    content.setEnabled(false);
    const arRootNode = scene.getTransformNodeById('content');
    arRootNode.setEnabled(true);
    arRootNode.rotate(Vector3.Right(), Math.PI / 2);
    arRootNode.position = new Vector3(0, content.position.x, 0);
    arRootNode.parent = content;
    content.rotationQuaternion = Quaternion.Identity();
    const FILTER_STRENGTH_ROT = 0.4;
    const FILTER_STRENGTH_TRANS = 0.7;
    let firstTracked = true;
    let currentSceneId = '';
    const getModelMatrix = (modelMatrix) => {
        return Matrix.FromValues(modelMatrix[0], -1 * modelMatrix[1], modelMatrix[2], modelMatrix[3], -1 * modelMatrix[4], modelMatrix[5], -1 * modelMatrix[6], modelMatrix[7], modelMatrix[8], -1 * modelMatrix[9], modelMatrix[10], modelMatrix[11], modelMatrix[12], -1 * modelMatrix[13], modelMatrix[14], modelMatrix[15]);
    };
    const updateContent = (content) => (result) => {
        if (result.isTracked) {
            const modelMatrix = getModelMatrix(result.modelMatrix);
            /// Update camera
            const cameraMatrix = modelMatrix.invert();
            const camera = scene.getCameraById('marker-camera');
            const rotationMatrix = cameraMatrix.getRotationMatrix();
            const quat = Quaternion.FromRotationMatrix(rotationMatrix);
            const position = cameraMatrix.getTranslation();
            camera.rotationQuaternion = quat;
            camera.position = position;
        }
        content.setEnabled(result.isTracked);
        adt.rootContainer.children
            .filter((el) => el.metadata.sceneId === currentSceneId)
            .forEach((el) => {
            el.isVisible = result.isTracked;
        });
        // Update firstTracked so it's false if we have lost tracking
        firstTracked =
            firstTracked && content.isEnabled()
                ? false
                : !content.isEnabled();
    };
    tracker.trackingState$.subscribe(updateContent(content));
};
