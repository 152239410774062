import { MeshBuilder, CreateSphereVertexData, CreateBoxVertexData, CreateTorusVertexData, CreateCylinderVertexData, CSG, StandardMaterial, Color3, Vector3, } from "@babylonjs/core";
import { assertExhaustive } from "../../library/dist/utilities/switch-case.guard";
export const createGroundPlane = (scene) => (ground) => {
    const groundPlane = MeshBuilder.CreateBox("GroundMesh", {
        width: ground.width,
        height: ground.height,
        depth: 0.001,
        updatable: true,
    }, scene);
    groundPlane.setEnabled(false);
    // This currently only works for geometry based excluders
    const excluderMeshes = ground.excluders
        .filter((x) => x.type === "geometry")
        .map((e) => {
        const excluderMesh = createExcluder(scene)(e);
        excluderMesh.position = new Vector3(e.transform.position[0], e.transform.position[2], e.transform.position[1]);
        excluderMesh.setEnabled(false);
        return excluderMesh;
    });
    const CSGplane = CSG.FromMesh(groundPlane);
    groundPlane.dispose();
    const CSGexcluders = excluderMeshes.map((e) => {
        const csg = CSG.FromMesh(e);
        return csg;
    });
    excluderMeshes.map((e) => e.dispose());
    // Recursively cut holes using CSG
    const buildCSGGround = (CSGplane, CSGexcluders, index) => {
        if (index === CSGexcluders.length) {
            return CSGplane;
        }
        else {
            return buildCSGGround(CSGplane.subtract(CSGexcluders[index]), CSGexcluders, index + 1);
        }
    };
    const finalCSG = buildCSGGround(CSGplane, CSGexcluders, 0);
    const finalMesh = finalCSG.toMesh("Ground", null, scene, false);
    //Throw out the temporary meshes
    // groundPlane.dispose(true, true);
    // excluderMeshes.map((e) => e.dispose(true, true));
    return finalMesh;
};
export const createExcluder = (scene) => (glTemplate) => {
    const excluder = createMeshFromTemplate(scene)(glTemplate);
    return excluder;
};
export const createMaterial = (scene, geometry) => {
    if (!geometry.material) {
        // throw new Error("Geometry has no material assigned");
        return;
    }
    let mat = new StandardMaterial(`material-${geometry.id}`, scene);
    mat.alpha = geometry.material.alpha;
    mat.diffuseColor = Color3.FromHexString(geometry.material.color);
    if (geometry.material.glow) {
        mat.diffuseColor = Color3.Black();
        mat.specularColor = Color3.Black();
        mat.emissiveColor = Color3.FromHexString(geometry.material.color);
    }
    return mat;
};
export const createWireFrame = (scene, geometry) => {
    if (!geometry.material) {
        // throw new Error("Geometry has no material assigned");
        console.log("Geometry has no material assigned");
        return;
    }
    let mat = new StandardMaterial(`material-${geometry.id}`, scene);
    mat.wireframe = true;
    return mat;
};
export const createMeshFromTemplate = (scene) => (glTemplate) => {
    /// Default and commons options for all geometry
    const options = {
        updatable: true,
    };
    switch (glTemplate.template.type) {
        case "Box":
            const box = MeshBuilder.CreateBox("Box", Object.assign(Object.assign({}, options), { width: glTemplate.template.width, height: glTemplate.template.height, depth: glTemplate.template.depth }), scene);
            box.id = glTemplate.id;
            return box;
        case "Sphere":
            const sphere = MeshBuilder.CreateSphere("Sphere", Object.assign(Object.assign({}, options), { diameterX: glTemplate.template.diameterX, diameterY: glTemplate.template.diameterY, diameterZ: glTemplate.template.diameterZ }), scene);
            sphere.id = glTemplate.id;
            return sphere;
        case "Cylinder":
            const cylinder = MeshBuilder.CreateCylinder("Cylinder", Object.assign(Object.assign({}, options), { height: glTemplate.template.height, diameterTop: glTemplate.template.diameterTop, diameterBottom: glTemplate.template.diameterBottom, tessellation: glTemplate.template.tessellation }), scene);
            cylinder.id = glTemplate.id;
            return cylinder;
        case "Torus":
            const torus = MeshBuilder.CreateTorus("Torus", Object.assign(Object.assign({}, options), { diameter: glTemplate.template.diameter, thickness: glTemplate.template.thickness, tessellation: glTemplate.template.tessellation }), scene);
            torus.id = glTemplate.id;
            return torus;
        default:
            assertExhaustive(glTemplate.template);
    }
};
/**
 * Update a mesh from a ModelGeometry. It's important to use the same ModelGeometry as the mesh was created from.
 * @param mesh The mesh to be updated
 * @param geometry The model geometry to update the mesh
 * @returns Updated mesh
 */
export const updateMesh = (mesh, geometry) => {
    const vertexData = (geometry) => {
        switch (geometry.type) {
            case "Box":
                return CreateBoxVertexData({
                    height: geometry.height,
                    width: geometry.width,
                    depth: geometry.depth,
                });
            case "Sphere":
                return CreateSphereVertexData({
                    diameterX: geometry.diameterX,
                    diameterY: geometry.diameterY,
                    diameterZ: geometry.diameterZ,
                });
            case "Torus":
                return CreateTorusVertexData({
                    diameter: geometry.diameter,
                    thickness: geometry.thickness,
                    tessellation: geometry.tessellation,
                });
            case "Cylinder":
                return CreateCylinderVertexData({
                    diameterTop: geometry.diameterTop,
                    diameterBottom: geometry.diameterBottom,
                    height: geometry.height,
                });
            default:
                assertExhaustive(geometry);
        }
    };
    return vertexData(geometry).applyToMesh(mesh);
};
