import { MeshBuilder, Mesh, Color3, PhysicsImpostor, } from '@babylonjs/core';
import { SimpleMaterial } from '@babylonjs/materials';
export const createPlayerDummy = (scene) => {
    const player = MeshBuilder.CreateSphere('playerDummy', { segments: 12, diameter: 10, sideOrientation: Mesh.DOUBLESIDE }, scene);
    player.position.set(0, 0, 0);
    const dummyMaterial = new SimpleMaterial('playerDummyMaterial', scene);
    dummyMaterial.diffuseColor = new Color3(0, 0, 0);
    player.material = dummyMaterial;
    player.visibility = 0.0;
    player.isPickable = false;
    player.parent = scene.getNodeByName('Astetroids');
    player.physicsImpostor = new PhysicsImpostor(player, PhysicsImpostor.SphereImpostor, { mass: 0 }, scene);
    return player;
};
export const createProximityDummy = (scene) => {
    const proximityDummy = MeshBuilder.CreateSphere('proximityDummy', { segments: 12, diameter: 400 }, scene);
    proximityDummy.position.set(0, 0, 0);
    proximityDummy.parent = scene.getNodeByName('Astetroids');
    const proximityMaterial = new SimpleMaterial('proximityMaterial', scene);
    proximityMaterial.diffuseColor = new Color3(0, 0, 0);
    proximityDummy.material = proximityMaterial;
    proximityDummy.visibility = 0.0;
    proximityDummy.isPickable = false;
    return proximityDummy;
};
