import { Sound } from '@babylonjs/core/Audio/sound';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { VideoTexture } from '@babylonjs/core/Materials/Textures/videoTexture';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { createMaterial, createMeshFromTemplate, } from '../../../../library-babylon/src/mesh.functions';
import { handleExtraAnimations } from '../../../../library-babylon/src/animations';
// import { PreTransform } from '../../../../library-babylon/dist/pretransform';
import { createEmptyMeshFromGlModel, transformMesh } from '../mesh-functions';
import { AnnotateObject } from './annotation.utils';
import { ChromaKeyMaterial } from './chromakey.utils';
import { AssetTaskError, } from './content-handling.types';
import { updateMeshFromGlModelTransform } from './task-model.transforms';
import '@babylonjs/loaders/glTF';
import { TransformNode, Vector3, } from '@babylonjs/core';
/**
 * Setup animations from a mesh task from a glModel object
 * @param events$ Subject to send events on for animation OnEnds
 */
const setupAnimations = (events$, glObject, mesh, animations, scene) => {
    var _a;
    if ('animation' in glObject &&
        glObject.animation &&
        animations.length > 0) {
        const animation = animations[glObject.animation.index];
        animation.loopAnimation = glObject.animation.loop;
        mesh.metadata.animation = animation;
        if (((_a = glObject.animation.onEnd) === null || _a === void 0 ? void 0 : _a.type) !== 'none') {
            animation.onAnimationGroupEndObservable.add(() => {
                events$.next(glObject.animation.onEnd);
            });
        }
    }
    if ('extraAnimations' in glObject && glObject.extraAnimations) {
        handleExtraAnimations(glObject, mesh, scene);
    }
};
/**
 * Asset task factory. Setup the diffrent asset task
 * @param scene
 * @param animations
 */
export const AssetTasks = (scene, events$) => {
    /// Setup a empty mesh function creater
    const emptyMesh = createEmptyMeshFromGlModel(scene);
    const geometryBuilder = createMeshFromTemplate(scene);
    /// Get the content transformnode
    const content = scene.getTransformNodeById('content');
    const onErrorBase = (task, message, exception) => {
        throw new AssetTaskError(exception);
    };
    /// TODO create return type that defines the two callback onSuccess and onError, return higer order function that binds these to the task object
    return {
        glModelTask: (sceneId) => (glModelTask) => {
            const glModel = glModelTask.glModel;
            const onSucces = (task) => {
                const arContent = emptyMesh(glModel);
                arContent.parent = content;
                transformMesh(arContent, [
                    updateMeshFromGlModelTransform(glModel),
                ]);
                arContent.setEnabled(false);
                arContent.metadata = {
                    action: glModel.action,
                    base: true,
                    sceneId: sceneId,
                };
                // stop all animations, then setup metadata
                task.loadedAnimationGroups.forEach((a) => a.stop());
                setupAnimations(events$, glModel, arContent, task.loadedAnimationGroups, scene);
                AnnotateObject(scene, glModel, arContent);
                /// Pretransforms
                const transformNode = glModel.pretransform
                    ? addPretransform(task.loadedMeshes, glModel)
                    : new TransformNode('Pre Transforms');
                transformNode.parent = content;
                task.loadedMeshes[0].parent = transformNode;
                transformNode.parent = arContent;
            };
            /// Example of oveewring error handler
            const onError = (task, message, exception) => {
                throw new AssetTaskError(message);
            };
            glModelTask.task.onSuccess = onSucces;
            glModelTask.task.onError = onError;
            return glModelTask;
        },
        glSurfaceTask: (sceneId, events$) => (surfaceTask) => {
            const glSurface = surfaceTask.glSurface;
            const onSuccess = () => {
                var _a;
                const material = new StandardMaterial(`${glSurface.name}-material`, scene);
                let texture;
                switch (glSurface.textureType) {
                    case 'video':
                        texture = new VideoTexture(`${glSurface.name}-video`, glSurface.textureUrl, scene, false);
                        /// These settings are necesarry for playing audio and video
                        texture.video.autoplay = false;
                        texture.video.muted = true;
                        texture.video.loop = glSurface.loop;
                        material.diffuseTexture = texture;
                        // If something is set to happen at the end of the video, we set the callback here
                        if (((_a = glSurface.onEnd) === null || _a === void 0 ? void 0 : _a.type) !== 'none') {
                            texture.video.onended = (event) => {
                                events$.next(glSurface.onEnd);
                            };
                        }
                        break;
                    case 'image':
                        //TODO: optimize in future so we only use opacity maps for pngs and gifs (and perhaps only if they have transparency)
                        material.opacityTexture = new Texture(glSurface.textureUrl, scene);
                        material.diffuseTexture = new Texture(glSurface.textureUrl, scene);
                        break;
                    default:
                }
                material.emissiveColor = new Color3(1, 1, 1);
                material.specularColor = new Color3(0, 0, 0);
                material.backFaceCulling = false;
                material.disableLighting = true;
                let plane = MeshBuilder.CreatePlane(glSurface.name, {
                    width: glSurface.size.width,
                    height: glSurface.size.height,
                }, scene);
                // exclude surface from being part of glowLayer
                const glowLayer = scene.getGlowLayerByName('glow-layer');
                glowLayer.addExcludedMesh(plane);
                transformMesh(plane, [
                    updateMeshFromGlModelTransform(glSurface),
                ]);
                plane.id = glSurface.id;
                plane.metadata = {
                    base: true,
                    sceneId,
                    // Den her nede er sku ikke helt go'..
                    video: glSurface.textureType === 'image'
                        ? null
                        : texture.video,
                    type: 'surface',
                    textureType: glSurface.textureType,
                    loop: glSurface.loop,
                    muted: glSurface.muted,
                    textureUrl: glSurface.textureUrl,
                    size: glSurface.size,
                    onEnd: glSurface.onEnd
                        ? glSurface.onEnd
                        : { type: 'none' },
                };
                setupAnimations(events$, glSurface, plane, [], scene);
                if (glSurface.chromakey) {
                    plane.material = ChromaKeyMaterial(scene)(texture);
                }
                else {
                    plane.material = material;
                }
                AnnotateObject(scene, glSurface, plane);
                plane.parent = content;
                plane.setEnabled(false);
            };
            surfaceTask.task.onSuccess = onSuccess;
        },
        geometryTask: (sceneId, events$) => (geometryTask) => {
            const onSuccess = () => {
                var _a, _b;
                /// Create AR root content node
                const arContent = emptyMesh(geometryTask.glTemplate);
                arContent.parent = content;
                transformMesh(arContent, [
                    updateMeshFromGlModelTransform(geometryTask.glTemplate),
                ]);
                arContent.setEnabled(false);
                arContent.metadata = {
                    action: geometryTask.glTemplate.action,
                    base: true,
                    sceneId: sceneId,
                };
                const gemoetryMesh = geometryBuilder(geometryTask.glTemplate);
                if (!((_a = geometryTask.glTemplate) === null || _a === void 0 ? void 0 : _a.occluderOnly)) {
                    gemoetryMesh.material = createMaterial(scene, geometryTask.glTemplate);
                }
                // Add mesh to glow layer so only geometry will be affected by glow-layer and not surfaces which are also emmisive
                const glowLayer = scene.getGlowLayerByName('glow-layer');
                glowLayer.addIncludedOnlyMesh(gemoetryMesh);
                if ((_b = geometryTask.glTemplate) === null || _b === void 0 ? void 0 : _b.occluderOnly) {
                    const engine = scene.getEngine();
                    gemoetryMesh.onBeforeRenderObservable.add(() => {
                        engine.setColorWrite(false);
                    });
                    gemoetryMesh.onAfterRenderObservable.add(() => {
                        engine.setColorWrite(true);
                    });
                }
                setupAnimations(events$, geometryTask.glTemplate, arContent, [], scene);
                AnnotateObject(scene, geometryTask.glTemplate, arContent);
                /// Pretransforms and object hirachy
                const transformNode = geometryTask.glTemplate.pretransform
                    ? addPretransform([gemoetryMesh], geometryTask.glTemplate)
                    : new TransformNode('Pre Transforms');
                transformNode.parent = content;
                gemoetryMesh.parent = transformNode;
                transformNode.parent = arContent;
                /// Ground is not part of content node
                /// Content node get's some extra transformations
                // if (templateTask.glTemplate.template.type === 'Ground') {
                //     const arRootNode =
                //         scene.getTransformNodeById('arRootNode');
                //     mesh.parent = arRootNode;
                //     /// Yes this one is werid! We are rotating something too much somewhere...
                //     mesh.rotation = new Vector3(Math.PI * 0.5, 0, 0);
                //     return;
                // }
            };
            geometryTask.task.onSuccess = onSuccess;
        },
        audioTask: (sceneId, events$) => (audioTask) => {
            if (!audioTask.task)
                return;
            const onSuccess = (task) => {
                var _a;
                const audio = new Sound(sceneId, task.data, scene, () => { }, {
                    loop: audioTask.audio.loop,
                    autoplay: false,
                });
                // If something is set to happen at the end of the audio, we set the callback here
                if (((_a = audioTask.audio.onEnd) === null || _a === void 0 ? void 0 : _a.type) !== 'none') {
                    audio.onended = () => {
                        window.setTimeout(() => events$.next(audioTask.audio.onEnd), 500);
                    };
                }
                scene.sounds.push(audio);
            };
            audioTask.task.onSuccess = onSuccess;
            audioTask.task.onError = onErrorBase; /// Just use base error handler
        },
    };
};
/**
 * Asset task handler. Executes all the asset tasks
 */
export const AssetTaskHandler = (taskHandler, events$) => (sceneTasks) => {
    // TODO make all tasks handled by looping over the sceneTask object. This requies a generic interface and to union task types
    sceneTasks.meshTasks.map(taskHandler.glModelTask(sceneTasks.sceneId));
    sceneTasks.surfaceTask.map(taskHandler.glSurfaceTask(sceneTasks.sceneId, events$));
    sceneTasks.geometryTasks.map(taskHandler.geometryTask(sceneTasks.sceneId, events$));
    taskHandler.audioTask(sceneTasks.sceneId, events$)(sceneTasks.audioTask);
};
/// TODO refac to library
const addPretransform = (meshes, glModel) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const transformNode = new TransformNode('Pre Transforms');
    if ((_a = glModel.pretransform) === null || _a === void 0 ? void 0 : _a.position) {
        meshes[0].position = new Vector3((_b = glModel.pretransform) === null || _b === void 0 ? void 0 : _b.position[0], (_c = glModel.pretransform) === null || _c === void 0 ? void 0 : _c.position[1], (_d = glModel.pretransform) === null || _d === void 0 ? void 0 : _d.position[2]);
    }
    if ((_e = glModel.pretransform) === null || _e === void 0 ? void 0 : _e.rotation) {
        transformNode.rotation = new Vector3((_f = glModel.pretransform) === null || _f === void 0 ? void 0 : _f.rotation[0], (_g = glModel.pretransform) === null || _g === void 0 ? void 0 : _g.rotation[1], (_h = glModel.pretransform) === null || _h === void 0 ? void 0 : _h.rotation[2]);
    }
    if ((_j = glModel.pretransform) === null || _j === void 0 ? void 0 : _j.scaling) {
        transformNode.scaling = new Vector3((_k = glModel.pretransform) === null || _k === void 0 ? void 0 : _k.scaling[0], (_l = glModel.pretransform) === null || _l === void 0 ? void 0 : _l.scaling[1], (_m = glModel.pretransform) === null || _m === void 0 ? void 0 : _m.scaling[2]);
    }
    return transformNode;
};
