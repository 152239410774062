import { DataError } from './error-handling/data.exception';
import { showElements, hideElements } from './viewer.utils';
export const Splash = (hololinkManager) => {
    const hololink = hololinkManager.hololink();
    // Show error modal if no hololink
    if (!hololink) {
        throw new DataError();
    }
    const isPropublish = hololink.publishing.status === 'published' &&
        hololink.publishing.options.removeWatermark;
    if (isPropublish) {
        /// TODO show custom branding splash if pro publish
    }
    else {
        /// TODO show hololink splash
    }
    const startContainer = document.getElementById('full-screen-container');
    const loadingScreen = document.getElementById('loading');
    return {
        ready: () => {
            showElements(['#full-screen-container']);
            hideElements(['#loading']);
            startContainer.classList.remove('fade-out');
            startContainer.classList.add('fade-in');
            loadingScreen.classList.add('fade-out');
        },
    };
};
