import { Subject } from 'rxjs';
export const HUDManager = (hudRoot) => {
    const hudEvents$ = new Subject();
    const HUDElement2HTML = (el) => {
        let element;
        switch (el.type) {
            case 'text': {
                element = document.createElement('p');
                element.classList.add(el.show);
                element.onclick = () => {
                    if (el.dismissable) {
                        element.style.opacity = '0';
                    }
                };
                const sp = document.createElement('span');
                sp.innerText = el.contents;
                element.append(sp);
                break;
            }
            case 'icon': {
                element = document.createElement('img');
                element.src = el.url;
                break;
            }
            case 'button': {
                element = document.createElement('button');
                if (el.faIcon) {
                    const icon = document.createElement('i');
                    icon.classList.add('fas', `fa-${el.faIcon.name}`);
                    element.appendChild(icon);
                    const pos = el.faIcon.position;
                    element.style.setProperty('--flex-direction', {
                        left: 'row',
                        right: 'row-reverse',
                        above: 'column',
                        below: 'column-reverse',
                    }[pos]);
                    const sp = document.createElement('span');
                    sp.innerText = el.text;
                    element.append(sp);
                }
                break;
            }
        }
        element.id = el.id;
        element.classList.add(el.show);
        if ('action' in el) {
            element.onclick = (ev) => {
                hudEvents$.next(el.action);
            };
        }
        if ('font' in el) {
            element.style.setProperty('--font-size', el.font.fontSize + '%');
            element.style.setProperty('--font-family', el.font.fontFamily);
        }
        if ('cornerRadius' in el) {
            element.style.setProperty('--border-radius', el.cornerRadius + 'px');
        }
        // TODO this hack was here for a reason, but we can't have it anymore - what to do?
        // if (getMobileOperatingSystem() === 'Android' && el.vertical === 'end') {
        //     element.style.marginBottom = '3rem';
        // }
        if (el.fgColor && el.bgColor) {
            element.style.setProperty('--fg-color', el.fgColor);
            element.style.setProperty('--bg-color', el.bgColor);
        }
        element.style.setProperty('--width', el.width.replace('%', 'vw'));
        element.style.setProperty('top', el.verticalOffset + '%');
        element.style.setProperty('left', el.horizontalOffset + '%');
        return element;
    };
    const HUDSpec2HTML = (hud) => {
        const containers = hud.elements.map(HUDElement2HTML);
        return { html: containers, events$: hudEvents$.asObservable() };
    };
    const updateHUD = (scene) => {
        hudRoot.innerHTML = '';
        const hud = HUDSpec2HTML(scene.hud);
        hud.html.forEach((el) => {
            hudRoot.appendChild(el);
        });
    };
    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor;
        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }
        if (/android/i.test(userAgent)) {
            return 'Android';
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }
        return 'unknown';
    }
    return {
        update: updateHUD,
        events$: hudEvents$.asObservable(),
    };
};
