"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnchorUrl = exports.defaultAnchor = void 0;
exports.defaultAnchor = "https://storage.googleapis.com/hololink/default-anchor-v4.png";
const getAnchorUrl = (hololinkScene, hololink) => {
    var _a, _b;
    const cacheBust = Math.random().toString(36).substring(2, 10);
    /// Sometimes we got the full URL
    if ((_a = hololinkScene.anchor) === null || _a === void 0 ? void 0 : _a.url.includes("http")) {
        return hololinkScene.anchor.url;
    }
    const anchorUrl = hololinkScene.anchor
        ? `https://storage.googleapis.com/${hololink.organizationId}/hololink/${hololink._id}/${(_b = hololinkScene.anchor) === null || _b === void 0 ? void 0 : _b.url}?=${cacheBust}`
        : exports.defaultAnchor;
    return anchorUrl;
};
exports.getAnchorUrl = getAnchorUrl;
