var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isMobile } from '../managers/webcam.manager';
export const VideoElement = (mediaConstraint) => __awaiter(void 0, void 0, void 0, function* () {
    const video = document.getElementById('inputVideo');
    const renderCanvas = document.getElementById('renderCanvas');
    // video.style.display = 'none';
    let isplaying = false;
    const stream = yield navigator.mediaDevices.getUserMedia({
        video: mediaConstraint,
        audio: false,
    });
    const track = stream.getVideoTracks()[0];
    const play = () => __awaiter(void 0, void 0, void 0, function* () {
        video.srcObject = stream;
        yield video.play();
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        renderCanvas.width = video.videoWidth;
        renderCanvas.height = video.videoHeight;
        isplaying = true;
    });
    const visible = (show) => {
        video.style.display = show ? 'block' : 'none';
    };
    visible(false);
    return {
        load: () => __awaiter(void 0, void 0, void 0, function* () { return play(); }),
        play: () => __awaiter(void 0, void 0, void 0, function* () { return video.play(); }),
        pause: () => video.pause(),
        setConstraint: (constraint) => __awaiter(void 0, void 0, void 0, function* () {
            yield track.applyConstraints(constraint);
            return {
                width: (isMobile()
                    ? track.getConstraints().height
                    : track.getConstraints().width),
                height: (isMobile()
                    ? track.getConstraints().width
                    : track.getConstraints().height),
            };
        }),
        getSettings: () => track.getSettings(),
        size: () => {
            return {
                width: video.videoWidth,
                height: video.videoHeight,
            };
        },
        visible,
        isPlaying: () => isplaying,
        element: () => video,
    };
});
export const CameraConstraints = {
    qvga: {
        width: { exact: 320 },
        height: { exact: 240 },
        facingMode: 'environment',
    },
    vga: {
        width: { exact: 480 },
        height: { exact: 640 },
        facingMode: 'environment',
        frameRate: 24,
    },
    full: {
        width: window.outerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
        height: window.outerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
        facingMode: 'environment',
    },
    test: {
        width: 650,
        height: 350,
        facingMode: 'environment',
    },
    'mobile-portrait': {
        width: window.outerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
        height: window.outerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
        facingMode: 'environment',
    },
    'mobile-landscape': {
        width: window.outerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
        height: window.outerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
        facingMode: 'environment',
    },
};
export const GetConstraint = (resolution = 'mobile-portrait') => {
    return CameraConstraints[resolution];
};
