import { Subject } from 'rxjs';
export class SceneTimer {
    constructor() {
        this.timerEvents$ = new Subject();
        this.start = Date.now();
        this.remaining = Infinity;
        this.events$ = this.timerEvents$.asObservable();
        this.isEnabled = false;
        // Checks the current scene object for a timer property and sets the SceneTimer properties accordingly.
        // If no timer property is found, it clears any active timer there may be left over from the previous scene
        this.update = (hlScene) => {
            if (!hlScene.timer) {
                this.reset();
                this.isEnabled = false;
                return;
            }
            this.remaining = hlScene.timer.timeOutInSeconds * 1000;
            this.callback = () => {
                this.timerEvents$.next(hlScene.timer.onEnd);
            };
            this.isEnabled = true;
        };
        // Clears active timeOut and calculates remaining time
        this.pause = () => {
            if (!this.timerId || !this.isEnabled) {
                return;
            }
            window.clearTimeout(this.timerId);
            this.remaining -= Date.now() - this.start;
        };
        //Clears active timeOut and starts a new timeOut with remaining time
        this.resume = () => {
            if (!this.isEnabled) {
                return;
            }
            this.start = Date.now();
            window.clearTimeout(this.timerId);
            this.timerId = window.setTimeout(this.callback, this.remaining);
        };
        // Clears the active timeOut - and resets properties and callbacks just for safe measures
        this.reset = () => {
            if (!this.timerId) {
                return;
            }
            this.callback = () => { };
            this.remaining = Infinity;
            window.clearTimeout(this.timerId);
            this.timerId = undefined;
            this.isEnabled = false;
        };
        // console.log('----- Creating a timer from');
    }
}
