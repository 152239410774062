var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CanvasElement } from '../element-factories/canvas.factory';
import { base64toBlob } from '../utilities/dom/image.utils';
export const createScreenshot = (renderer, removeWatermark) => {
    return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
        const videoCanvas = (document.getElementById('videoCanvas'));
        // Screenshot should be same size as video/render canvas
        const size = {
            width: videoCanvas.width,
            height: videoCanvas.height,
        };
        // We need to add the hololink watermark to the screenshot as well but not if hololink is pro published
        const watermark = new Image(size.width, size.height);
        watermark.src =
            'https://hololink.ams3.cdn.digitaloceanspaces.com/viewer/images/hololink-watermark-v8-cropped.png';
        // Creating new canvas to draw screenshot onto
        const screenshotCanvas = CanvasElement(size);
        const screenshotctx = screenshotCanvas.getContext();
        // Getting screenshot of the actual 3D models from the babylon renderer
        const rendererData = yield renderer.screenshot(size);
        const renderImage = new Image();
        renderImage.src = rendererData;
        const canvasElement = screenshotCanvas.getElement();
        renderImage.onload = () => {
            screenshotctx.drawImage(videoCanvas, 0, 0);
            screenshotctx.drawImage(renderImage, 0, 0);
            if (!removeWatermark) {
                screenshotctx.drawImage(watermark, 40, 10, 300, 50);
            }
            const dataURI = canvasElement.toDataURL();
            const blob = base64toBlob(dataURI);
            const url = URL.createObjectURL(blob);
            resolve(url);
        };
    }));
};
