import { AnimationGroup, Animation, Vector3, BackEase, BounceEase, EasingFunction, ElasticEase, CircleEase, QuinticEase, Angle, } from "@babylonjs/core";
const FRAMERATE = 60;
const makeSingleAnimation = (name, datatype, source, target, time, easing) => {
    const animation = new Animation(name, name, FRAMERATE, datatype, Animation.ANIMATIONLOOPMODE_CYCLE);
    const keys = [];
    keys.push({
        frame: 0,
        value: source,
    });
    keys.push({
        frame: FRAMERATE * time,
        value: target,
    });
    animation.setKeys(keys);
    if (easing) {
        const ease = easings[easing.name](easing);
        animation.setEasingFunction(ease);
    }
    return animation;
};
export const handleExtraAnimations = (o, mesh, scene) => {
    if (mesh.metadata.extraAnimations) {
        const anims = mesh.metadata.extraAnimations;
        anims.forEach((a) => {
            a.stop();
            a.dispose();
        });
        mesh.metadata.extraAnimations = null;
    }
    const spec = o.extraAnimations;
    if (!spec) {
        return;
    }
    // spec.type === "simple" -- move this to a switch if/when there's more types
    return (() => {
        const anims = [];
        if (spec.position) {
            const anim = makeSingleAnimation("position", Animation.ANIMATIONTYPE_VECTOR3, Vector3.FromArray(o.transform.position), Vector3.FromArray(spec.position.target), spec.position.time, spec.position.easing);
            const group = new AnimationGroup(`hlPosition-${o.name}`, scene);
            group.loopAnimation = spec.position.loop;
            group.addTargetedAnimation(anim, mesh);
            anims.push(group);
        }
        if (spec.rotation) {
            const anim = makeSingleAnimation("rotation", Animation.ANIMATIONTYPE_VECTOR3, Vector3.FromArray(o.transform.rotation.map((a) => Angle.FromDegrees(a).radians())), Vector3.FromArray(spec.rotation.target.map((a) => Angle.FromDegrees(a).radians())), spec.rotation.time, spec.rotation.easing);
            const group = new AnimationGroup(`hlRotation-${o.name}`, scene);
            group.loopAnimation = spec.rotation.loop;
            group.addTargetedAnimation(anim, mesh);
            anims.push(group);
        }
        if (spec.scaling) {
            const anim = makeSingleAnimation("scaling", Animation.ANIMATIONTYPE_VECTOR3, Vector3.FromArray(o.transform.scaling), Vector3.FromArray(spec.scaling.target), spec.scaling.time, spec.scaling.easing);
            const group = new AnimationGroup(`hlScaling-${o.name}`, scene);
            group.loopAnimation = spec.scaling.loop;
            group.addTargetedAnimation(anim, mesh);
            anims.push(group);
        }
        mesh.metadata.extraAnimations = anims;
    })();
};
const easingModes = {
    easeIn: EasingFunction.EASINGMODE_EASEIN,
    easeOut: EasingFunction.EASINGMODE_EASEOUT,
    easeInOut: EasingFunction.EASINGMODE_EASEINOUT,
};
const easings = {
    none: (easing) => {
        return null;
    },
    circle: (easing) => {
        const e = new CircleEase();
        const mode = easingModes[easing.mode];
        e.setEasingMode(mode);
        return e;
    },
    back: (easing) => {
        const e = new BackEase();
        const mode = easingModes[easing.mode];
        e.setEasingMode(mode);
        return e;
    },
    elastic: (easing) => {
        const e = new ElasticEase();
        const mode = easingModes[easing.mode];
        e.setEasingMode(mode);
        return e;
    },
    bounce: (easing) => {
        const e = new BounceEase();
        const mode = easingModes[easing.mode];
        e.setEasingMode(mode);
        return e;
    },
    quintic: (easing) => {
        const e = new QuinticEase();
        const mode = easingModes[easing.mode];
        e.setEasingMode(mode);
        return e;
    },
};
