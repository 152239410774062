import { Effect, ShaderMaterial } from "@babylonjs/core";
export const ChromaKeyMaterial = (scene) => (texture) => {
    chromakeyShaders();
    const shaderMaterial = new ShaderMaterial("shader", scene, {
        vertex: "basic",
        fragment: "videoFx",
    }, {
        needAlphaBlending: true,
        attributes: ["position", "normal", "uv", "world0", "world1", "world2", "world3"],
        uniforms: ["world", "worldView", "viewProjection", "worldViewProjection", "view", "projection"]
    });
    shaderMaterial.setTexture("textureSampler", texture);
    shaderMaterial.setFloat('thresholdSensitivity', 0.24);
    shaderMaterial.setFloat('smoothing', 0.2);
    shaderMaterial.backFaceCulling = true;
    return shaderMaterial;
};
export const chromakeyShaders = () => {
    Effect.ShadersStore["basicVertexShader"] = `
precision highp float;
 
// Attributes
attribute vec3 position;
attribute vec3 normal;
attribute vec2 uv;
 
// Uniforms
uniform mat4 worldViewProjection;
uniform float time;
 
// Varying
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUV;

varying float noise;
 
void main(void) {
    vec3 v = position;
 
    gl_Position = worldViewProjection * vec4(v, 1.0);
 
    vPosition = position;
    vNormal = normal;
    vUV = uv;
}
`;
    Effect.ShadersStore['videoFxFragmentShader'] = `
precision highp float;
 
uniform sampler2D textureSampler;
varying vec2 vUV;
//uniform mat4 worldView;

//recommended 0.24
uniform float thresholdSensitivity;

//recommended 0.3
uniform float smoothing;

vec4 transparentColor = vec4(0.0, 0.0, 0.0, 0.0);

void main(void) {
  vec2 uv = vUV.xy;
  vec4 sample_color = texture2D(textureSampler, uv);
  
  // Get chroma key color
  vec4 chromaColorAuto = texture2D(textureSampler, vec2(1.0, 1.0));
  vec3 newChromaColorAuto = vec3(chromaColorAuto.r, chromaColorAuto.g, chromaColorAuto.b);

  float maskY = 0.2989 * newChromaColorAuto.r + 0.5866 * newChromaColorAuto.g + 0.1145 * newChromaColorAuto.b;
  float maskCr = 0.7132 * (newChromaColorAuto.r - maskY);
  float maskCb = 0.5647 * (newChromaColorAuto.b - maskY);
  float Y = 0.2989 * sample_color.r + 0.5866 * sample_color.g + 0.1145 * sample_color.b;
  float Cr = 0.7132 * (sample_color.r - Y);
  float Cb = 0.5647 * (sample_color.b - Y);
  float blendValue = smoothstep(thresholdSensitivity, thresholdSensitivity + smoothing, distance(vec2(Cr, Cb), vec2(maskCr, maskCb)));
  gl_FragColor = vec4(sample_color.rgb * blendValue, 1.0 * blendValue);
}`;
};
