var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { merge } from 'rxjs';
import { screenshotModal } from '../services/modal.service';
import { createScreenshot } from '../function-helpers/screenshot';
export const EventManager = (hololinkmanager, renderer, ...events) => {
    const subscription = merge(...events).subscribe((action) => __awaiter(void 0, void 0, void 0, function* () {
        switch (action.type) {
            case 'change_scene':
                hololinkmanager.setScene(action.targetScene);
                break;
            case 'open_link':
                window.location.assign(action.URL);
                break;
            case 'screenshot':
                const hololink = hololinkmanager.hololink();
                const removeWatermark = hololink.publishing.status === 'published'
                    ? hololink.publishing.options.removeWatermark
                    : false;
                const screenshot = yield createScreenshot(renderer, removeWatermark);
                screenshotModal.show(screenshot);
                break;
            case 'none':
                break;
        }
    }));
    return {};
};
