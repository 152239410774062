import { HololinkError } from '../../error-handling/base.exception';
export class AssetTaskError extends HololinkError {
    // userMessage = `Asset couldn't load`;
    constructor(message, ...args) {
        super(...args);
        this.userMessage = 'An data error occured';
        this.logMessage = message;
        Error.captureStackTrace(this, AssetTaskError);
    }
}
