import TrackerWorker from "worker-loader!./tracker.worker";
export const TrackerHandler = () => {
    const trackerWorker = new TrackerWorker();
    let listeners = new Map();
    /// Handle all messages return from  our wasm worker
    trackerWorker.onmessage = event => {
        if (!listeners.has(event.data.name))
            return;
        listeners.get(event.data.name)(event.data.result);
    };
    return {
        load: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'load'
                });
                /// Add resolve to listeners as a clousre
                listeners.set('load', () => resolve());
            });
        },
        init: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'init'
                });
                /// Add resolve to listeners as a clousre
                listeners.set('init', () => resolve());
            });
        },
        reset: (homography, preppedScene, features, height, width, imageTargetWidth, imageTargetHeight) => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'reset',
                    args: [homography, preppedScene, features, height, width, imageTargetWidth, imageTargetHeight]
                });
                /// Add resolve to listeners as a clousre
                listeners.set('reset', () => resolve());
            });
        },
        processFrame: (imageData, timestamp) => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'processFrame',
                    args: [imageData, timestamp]
                });
                /// Add resolve to listeners as a clousre
                listeners.set('processFrame', () => resolve());
            });
        },
        generateResult: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'generateResult'
                });
                /// Add resolve to listeners as a clousre
                listeners.set('generateResult', (result) => resolve(result));
            });
        },
        getFeatures: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'getFeatures'
                });
                /// Add resolve to listeners as a clousre
                listeners.set('getFeatures', (features) => resolve(features));
            });
        },
        destroy: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                trackerWorker.postMessage({
                    name: 'destroy',
                });
                /// Add resolve to listeners as a clousre
                listeners.set('destroy', () => resolve());
            });
        }
    };
};
