var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { showElements, hideElements } from './viewer.utils';
import { HUDManager } from './managers/hud.manager';
import { EventManager } from './managers/events.manager';
import { TrackingManager } from './tracker/tracking.manager';
import { SceneTimer } from './timer';
import { filter } from 'rxjs/operators';
import { changeLoadingText } from './loading/loading-utils';
import { VideoManager } from './tracker/video.manager';
import { BabylonTracker } from './webgl/babylon-tracker';
// TODO fjerne hololink-manager og giv hololink som argument her
export const startViewer = (hololinkmanager, statisticsController, initialTrackingType, renderer, modelManager, workerHandler, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    //// Video
    const videoHandler = yield VideoManager();
    /// Need to start video to get the actual size
    yield videoHandler.startVideo();
    videoHandler.showVideoElement(true);
    /// Tracking
    let tracker;
    if (initialTrackingType.type === 'marker') {
        const tracker = yield TrackingManager(workerHandler, videoHandler);
        yield tracker.setTarget(initialTrackingType.anchorUrl);
    }
    let hudmanager;
    let sceneTimer;
    let tracked = false;
    /// DOM references
    const hudRoot = document.querySelector('#hud');
    /*
        View state functions
    */
    /**
     * Called when we change scene
     * When the scene is loaded and ready onSceneReady gets called
     * @param scene
     */
    const onSceneChanged = (scene) => {
        modelManager
            .prepareScene(hololinkmanager.currentSceneId())
            .subscribe(() => onSceneReady(scene));
        hudmanager.update(scene);
        sceneTimer.update(scene);
        if (tracked) {
            showElements(['.tracking']);
            hideElements(['.searching']);
        }
        else {
            hideElements(['.tracking']);
            showElements(['.searching']);
        }
    };
    /**
     * Called each time a new scene loaded and ready
     * @param scene
     */
    const onSceneReady = (scene) => {
        const onSceneReadyStuffToHandle = () => {
            modelManager.startScene(hololinkmanager.currentSceneId());
            sdk.sceneReady(scene);
            if (scene.type === '360') {
                modelManager.resumeScene(hololinkmanager.currentSceneId());
                sceneTimer.resume();
            }
            if (scene.type === 'marker') {
                if (tracked) {
                    modelManager.resumeScene(hololinkmanager.currentSceneId());
                    sceneTimer.resume();
                }
                else {
                    modelManager.pauseScene(hololinkmanager.currentSceneId());
                    sceneTimer.pause();
                }
            }
        };
        modelManager.startScene(hololinkmanager.currentSceneId());
        onSceneReadyStuffToHandle();
        /// We need to ensure the onInitialSceneReady is fully finished before we run onSceneReady
        // if (initialized) {
        //     modelManager.startScene(hololinkmanager.currentSceneId());
        //     onSceneReadyStuffToHandle();
        // } else {
        //     onInitialSceneReady().then(() => onSceneReadyStuffToHandle());
        // }
        // StatisticEventOpenScene
        const event = {
            timestampClient: new Date(),
            type: 'Open scene',
            sceneId: hololinkmanager.currentSceneId(),
        };
        statisticsController.logEvent(event);
    };
    /**
     * Called just before a scene is closed
     * @param sceneId
     */
    const onSceneDone = (sceneId) => {
        modelManager.resetScene(sceneId);
    };
    /**
     * Each time tracking change states
     * @param isTracked
     * @returns void
     */
    const onTrackedChanged = (isTracked) => {
        /// Only update any tracking if we are ready to handle it
        // if (!initialized) return;
        var _a;
        // Update scope state
        tracked = isTracked;
        if (isTracked) {
            modelManager.resumeScene(hololinkmanager.currentSceneId());
            sceneTimer.resume();
            showElements(['.tracking']);
            hideElements([
                '#scanner',
                '#target-image',
                '#scanner-frame-settings',
                '#image-target',
                '#target-image-frame',
                '.searching',
            ]);
        }
        else {
            modelManager.pauseScene(hololinkmanager.currentSceneId());
            sceneTimer.pause();
            hideElements(['.tracking']);
            if (!hololink.customLoader ||
                ((_a = hololink.customLoader) === null || _a === void 0 ? void 0 : _a.showAnchorLocator) === true) {
                showElements(['#target-image-frame']);
            }
            showElements([
                '#scanner',
                '#target-image',
                '#scanner-frame-settings',
                '#image-target',
                '.searching',
            ]);
        }
    };
    /// Start viewer
    renderer.startVideos();
    modelManager.startScene(hololinkmanager.currentSceneId());
    /// Ensure tracking works
    // await tracker.start();
    hudmanager = HUDManager(hudRoot);
    sceneTimer = new SceneTimer();
    const hololink = hololinkmanager.hololink();
    /// TODO den her skal gennemses
    renderer.contentManager.progress$.subscribe((progress) => {
        if ((progress === null || progress === void 0 ? void 0 : progress.totalCount) === 0) {
            changeLoadingText(3);
        }
    });
    if (initialTrackingType.type === 'marker') {
        yield tracker.start();
        const babylonTracker = BabylonTracker(renderer.scene());
        tracker.enableTracking();
        tracker.trackingStateChanged$.subscribe(onTrackedChanged);
        babylonTracker(tracker, renderer.contentManager);
    }
    // TODO giv andre observable<SceneAction> her, f.eks. animations
    const eventmanager = EventManager(hololinkmanager, renderer, hudmanager.events$, sceneTimer.events$, renderer.events$);
    // StatisticEventCallToAction - fires on change_scene, open_url and none on all 3D content
    renderer.events$.subscribe((e) => {
        const event = {
            timestampClient: new Date(),
            type: 'Scene action',
            action: e,
        };
        statisticsController.logEvent(event);
    });
    // StatisticEventCallToAction - fires on change_scene, open_url, screenshot and none on all hud elements
    hudmanager.events$.subscribe((e) => {
        const event = {
            timestampClient: new Date(),
            type: 'Scene action',
            action: e,
        };
        statisticsController.logEvent(event);
    });
    hololinkmanager.currentScene$.subscribe(onSceneChanged);
    hololinkmanager.beforeSceneChange$
        .pipe(filter((s) => s !== null))
        .subscribe(onSceneDone);
});
